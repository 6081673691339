import React from 'react';

export interface ICopyFill {
  width: string;
  height: string;
  viewBox: string;
  onClick?: () => void;
  className?: string;
  id?: string;
  color?: string;
}

const CopyFill: React.FC<ICopyFill> = ({
                                         width,
                                         height,
                                         viewBox,
                                         onClick,
                                         className,
                                         id,
                                         color,
                                       }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <title>copyFill</title>
    <g filter="url(#filter0_d_138_304)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 9H16V5.67V5.67002C15.9945 4.1977 14.8023 3.0055 13.33 3H6.67V3C5.19768 3.00548 4.00548 4.19768 4 5.67V12.33L4 12.33C4.00547 13.8023 5.19766 14.9945 6.66998 15H10V18C10 19.6569 11.3432 21 13 21H19C20.6569 21 22 19.6569 22 18V12C22 10.3432 20.6569 9 19 9V9ZM10 12V13H6.67C6.29997 13 6 12.7 6 12.33V5.67C6 5.29997 6.29997 5 6.67 5H13.33C13.7 5 14 5.29997 14 5.67V9H13C11.3432 9 10 10.3432 10 12H10Z"
        fill={color}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_138_304"
        x="-3"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_138_304"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_138_304"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default CopyFill;

import React from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { theme as gusTheme } from '../gus.theme';

export interface ITheme {
    customTheme?: DefaultTheme;
    children?: React.ReactNode;
}

const Theme: React.FC<ITheme> = ({ children = null,  customTheme }) => (
    <ThemeProvider theme={customTheme || gusTheme}>
        {children}
    </ThemeProvider>
);

export default Theme;
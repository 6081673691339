import { palette } from './palette.theme';

export const colors = {
    // New Color Palette
    // Theme colors: Brand color, Text CTAs, Buttons, Menu Titles, Active States
    main: palette.blue500,
    primary1: palette.blue500,
    primary2: palette.blue600,
    // Accent Colors: Complement primary colors, Secondary actions, Name cards, Highlight icons
    accent1: palette.yellow600,
    accent2: palette.purple500,
    // Neutrals: Background, button text
    baseWhite: palette.white,
    // (Neutrals) Highlights, dividers, sidebar, and form fields, Inactive state
    baseLightest: palette.silver100,
    baseLighter: palette.silver400,
    baseLight: palette.silver800,
    // (Neutrals) Headings, body text, Inactive state Text
    baseDark: palette.gray400,
    baseDarker: palette.gray600,
    baseDarkest: palette.gray900,
    // Semantics: Light: Background / Dark: Text & Form Fields
    lightError: palette.red100,
    darkError: palette.red600,
    lightSuccess: palette.green100,
    darkSuccess: palette.green700,
    lightWarning: palette.orange100,
    darkWarning: palette.orange900,
    lightInfo: palette.teal100,
    darkInfo: palette.teal700,
    lightUpdate: palette.pink100,
    darkUpdate: palette.pink700,
    disabled: palette.silver900,

    palette,
};

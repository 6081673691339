import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import P from "@atoms/Typography/Text";
import Button from "@components/migratedComponents/molecules/Button";
import Input from "@molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  IUpdateLocationParams,
  updateLocation,
} from "@components/funnel/hooks/useLocationUpdate";
import SettingCard from "@molecules/SettingCard";
import { $WebsiteWrapper } from "./BusinessWebsite.sc";

const BusinessWebsite: FC = () => {
  const [editing, setEditing] = useState(false);
  const [updatingWebsite, setUpdatingWebsite] = useState(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const { handleSubmit, register, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: IUpdateLocationParams) =>
    updateLocation(params)
  );

  const submitLocationUpdates = async (params) => {
    setUpdatingWebsite(true);
    mutation.mutate(params, {
      onSuccess: (responseData) => {
        setEditing(false);
        setUpdatingWebsite(false);
        funnelData.backend.location_attributes = responseData;
        updateFrontend();
        alert.success(<p>Save was successful</p>);
      },
      onError: () => {
        setUpdatingWebsite(false);
        alert.error(
          <>
            <h3>Something went wrong.</h3>
            <p>Please try again later.</p>
          </>
        );
      },
    });
  };

  return (
    <SettingCard
      onClick={() => {
        setEditing(true);
      }}
      onOutsideClick={() => {
        setEditing(false);
      }}
    >
      <P size="3" color="baseDark" fontWeight={600}>
        WEBSITE
      </P>
      {editing ? (
        <form onSubmit={handleSubmit(submitLocationUpdates)}>
          <Input
            id="uuid"
            name="uuid"
            type="hidden"
            register={register()}
            value={funnelData.backend.location_attributes.uuid}
          />
          <Input
            name="website"
            register={register({
              required: "Please enter URL.",
            })}
            errors={errors.website}
            defaultValue={funnelData.backend.location_attributes.website}
          />
          <Button
            color="primary"
            type="submit"
            onClick={null}
            leftIcon={updatingWebsite ? "spinner" : null}
          >
            Save
          </Button>
        </form>
      ) : (
        funnelData.backend.location_attributes.website && (
          <$WebsiteWrapper>
            <P size="2" color="baseDarkest" margin="8px 0px 0px 0px">
              {funnelData.backend.location_attributes.website}
            </P>
          </$WebsiteWrapper>
        )
      )}
    </SettingCard>
  );
};

export default BusinessWebsite;

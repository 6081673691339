import styled from 'styled-components';

export const $ModalHeader = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
`;

export const $ModalHeaderCloseButton = styled.div`
    cursor: pointer;
    margin: 0px 0px auto auto;

    button {
        height: 24px;
        width: 24px;
        margin: 0px;
        padding: 0px;
    }
`;

export const $ModalBody = styled.div`
    font-family: 'Open Sans', sans-serif;
    clear: both;
    max-height: 684px;
    overflow-y: auto;

    .m-modal-transactional__content {
        padding: 16px 0px;
        ${(props) => props.theme.font.bodyTwo};
    }

    .m-modal-transactional__actions {
        display: flex;
        justify-content: flex-end;

        button {
            margin: 3px 3px 3px 16px;
        }
    }
`;

import styled, { css } from 'styled-components';
import Icon from '../../atoms/Icon';

export const $HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    display: none;
`;

export const $Checkbox = styled(Icon)`
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    transition: all 150ms;
    margin: 0 8px 2px 0;
`;

export const $CheckboxContainer = styled.div<{
  size: 'sm' | 'md' | 'max';
  disabled: boolean;
  deselected: boolean;
}>`
    display: flex;
    align-items: center;
    min-width: ${({ size }) =>
            size === 'sm' ? '120px' : size === 'md' ? '136px' : 'max-content'};
    height: auto;

    ${({ disabled }) =>
            disabled &&
            css`
                pointer-events: none;
                cursor: default;
            `}
    &:hover {
        cursor: pointer;
        ${(props) =>
                !props.deselected &&
                css`
                    color: ${({ theme }) => theme.colors.baseDarkest};

                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.baseDarkest} !important;
                        }
                    }

                    label {
                        color: ${({ theme }) => theme.colors.baseDarkest};
                    }
                `}
    }
`;

import React from 'react';

export interface IToggleLeft {
  width: string;
  height: string;
  viewBox: string;
  onClick?: () => void;
  className?: string;
  id?: string;
  color?: string;
}

const ToggleLeft: React.FC<IToggleLeft> = ({
                                             width,
                                             height,
                                             viewBox,
                                             onClick,
                                             className,
                                             id,
                                             color,
                                           }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <title>toggleLeft</title>
    <rect width="36" height="20" rx="10" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
      fill="white"
    />
  </svg>
);

export default ToggleLeft;

import React from "react";
import { $P1, $P2, $P3, $P4 } from "./P.sc";

export interface IP {
  size?: "1" | "2" | "3" | "4";
  color?: string;
  fontWeight?: number;
  id?: string;
  className?: string;
  children: React.ReactNode;
  margin?: string;
}

const P: React.FC<IP> = ({
                           size = "1",
                           color = "baseDarkest",
                           fontWeight = 400,
                           id,
                           className,
                           children,
                           margin = "0",
                         }) => {
  switch (size) {
    case "1":
      return (
        <$P1
          className={className}
          role="paragraph"
          id={id}
          color={color}
          fontWeight={fontWeight}
          margin={margin}
        >
          {children}
        </$P1>
      );
    case "2":
      return (
        <$P2
          className={className}
          role="paragraph"
          id={id}
          color={color}
          fontWeight={fontWeight}
          margin={margin}
        >
          {children}
        </$P2>
      );
    case "3":
      return (
        <$P3
          className={className}
          role="paragraph"
          id={id}
          color={color}
          fontWeight={fontWeight}
          margin={margin}
        >
          {children}
        </$P3>
      );
    default:
      return (
        <$P4
          className={className}
          role="paragraph"
          id={id}
          color={color}
          fontWeight={fontWeight}
          margin={margin}
        >
          {children}
        </$P4>
      );
  }
};

export default P;

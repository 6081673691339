import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import Tooltip from 'react-power-tooltip';
import { TIcons } from '../../atoms/Icon/iconsLib';
import ButtonIcon from '../ButtonIcon';
import Button from '../Button';
import { $ToggleTip } from './ToggleTip.sc';

export interface IToggleTip {
  icon?: TIcons;
  id?: string;
  color?: 'primary' | 'primary2' | 'secondary' | 'error' | 'baseDark';
  disabled?: boolean;
  className?: string;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<Element>,
  ) => void;
  iconSize?: 'sm' | 'md';
  tipSize?: 'sm' | 'md' | 'lg';
  positionX?: 'left' | 'right';
  positionY?: 'top' | 'bottom';
  withAction?: boolean;
  actionLabel?: string;
  actionColor?:
    | 'primary'
    | 'primary2'
    | 'secondary'
    | 'legacy'
    | 'success'
    | 'error'
    | 'dark';
  actionFx?: () => void;
  scrollRef?: MutableRefObject<HTMLDivElement>;
  children: ReactNode;
}

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const ToggleTip: React.FC<IToggleTip> = ({
                                           id = 'toggle-tip',
                                           icon = 'link',
                                           color = 'primary',
                                           disabled = false,
                                           className,
                                           onClick,
                                           iconSize = 'md',
                                           tipSize = 'md',
                                           positionX = 'left',
                                           positionY = 'bottom',
                                           withAction = false,
                                           actionLabel,
                                           actionColor = 'primary',
                                           actionFx,
                                           scrollRef,
                                           children,
                                         }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [buttonPosition, setButtonPosition] = useState({});

  const handleClick = (e: any) => {
    e.preventDefault();
    if (onClick) onClick(e);

    setShow((prev) => !prev);
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      if (['button', 'svg', 'path'].includes(e.target.tagName.toLowerCase())) {
        if (
          (e.target.id && new RegExp(`^${id}`).test(e.target.id)) ||
          (e.target.className && /m-toggle-tip--'/.test(e.target.className))
        ) {
          return;
        }
      }

      setButtonPosition({});
      setShow(false);
    }
  };

  const handleWindowResize = () => {
    setWindowSize(getWindowSize());
  };

  const buttonPositionObject = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    const x = rect?.x;
    const y = rect?.y;
    const container = document.getElementsByClassName(
      'rpt-textbox-container',
    )[0];
    // @ts-ignore
    const height = container?.offsetHeight || 0;
    return { x, y, height, scrollTop: 0 };
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setButtonPosition({ ...buttonPositionObject(), scrollTop });
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);

      return () => {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      };
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (show) {
      const scrollTop = scrollRef ? scrollRef.current?.scrollTop : 0;
      setButtonPosition({ ...buttonPositionObject(), scrollTop });
    }
  }, [show]);

  return (
    <$ToggleTip
      tipSize={tipSize}
      show={show}
      positionX={positionX}
      positionY={positionY}
      className={className}
      buttonPosition={buttonPosition}
      windowSize={windowSize}
    >
      <ButtonIcon
        id={`${id}-button-icon`}
        icon={icon}
        color={color}
        onClick={handleClick}
        disabled={disabled}
        size={iconSize}
        selected={show}
        className="m-toggle-tip--button-icon"
      />
      <div
        id={`${id}-toggle-tip`}
        style={{ position: 'absolute' }}
        className="m-toggle-tip--container"
        ref={containerRef}
      >
        <Tooltip
          id={`${id}-tooltip`}
          show={show}
          position={`${positionY} ${positionX}`}
          hoverBackground="#fff"
          className="m-toggle-tip--tooltip"
        >
          <div className="m-toggle-tip--tooltip--contents toggle-button-contents">
            {children}
          </div>

          {withAction ? (
            <div
              id={`${id}-action`}
              className="m-toggle-tip--tooltip--actions toggle-button-contents toggle-button-action"
            >
              <Button
                color={actionColor}
                variant="text"
                size="sm"
                rightIcon="arrowRight"
                onClick={actionFx}
              >
                {actionLabel}
              </Button>
            </div>
          ) : (
            <div className="m-toggle-tip--tooltip--actions toggle-button-contents toggle-button-action-empty" />
          )}
        </Tooltip>
      </div>
    </$ToggleTip>
  );
};

export default ToggleTip;

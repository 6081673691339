import styled from 'styled-components';

const $H = styled.h1<{
  color: string;
  margin: string;
}>`
    color: ${(props) => props.theme.colors[props.color]};
    font-weight: 700;
    width: fit-content;
    margin: ${(props) => props.margin};
`;

export const $H1 = styled($H)`
    ${(props) => props.theme.font.headerOne}
`;

export const $H2 = styled($H)`
    ${(props) => props.theme.font.headerTwo}
`;

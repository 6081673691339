import styled, { css } from 'styled-components';
import Icon from '../../atoms/Icon';
import { TIcons } from '../../atoms/Icon/iconsLib';

export type TButtonColor =
  | 'primary'
  | 'primary2'
  | 'darkInfo' // use only for text button
  | 'secondary'
  | 'legacy'
  | 'success'
  | 'error'
  | 'dark';

export type TButtonSize = 'sm' | 'md' | 'lg';

export interface IButtonStyles {
  color?: TButtonColor;
  size?: TButtonSize;
  leftIcon?: TIcons;
  rightIcon?: TIcons;
  selected?: boolean;
}

export const $Base = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))<IButtonStyles>`
    width: fit-content;
    padding: ${(props) => props.theme.button.padding};
    border-radius: ${(props) => props.theme.button.borderRadius};
    box-shadow: ${(props) => props.theme.button.boxShadow};
    outline-offset: ${(props) => props.theme.button.outlineOffset};
    display: flex;
    align-items: center;

    &:focus {
        outline: ${(props) => props.theme.button.focus.outline};
        border-radius: ${(props) => props.theme.button.focus.borderRadius};
        box-shadow: ${(props) => props.theme.button.focus.boxShadow};
    }

    &:disabled {
        opacity: ${(props) => props.theme.button.disabled.opacity};
        background-color: ${(props) => props.theme.button.disabled.fill};
        pointer-events: none;
    }

    &:hover {
        cursor: pointer;
    }

    ${(props) => {
        if (props.size === 'sm')
            return css`
                ${props.theme.font.bodyThree}
            `;

        if (props.size === 'md')
            return css`
                ${props.theme.font.bodyTwo}
            `;

        return css`
            ${props.theme.font.bodyOne}
        `;
    }}

    font-weight: bold;
`;

export const $RightIcon = styled(Icon)`
    margin-left: 4px;
`;

export const $LeftIcon = styled(Icon)`
    margin-right: 4px;
`;

export const $Fill = styled($Base)`
    border: transparent;

    &:active {
        border-radius: ${(props) => props.theme.button.fill.active.borderRadius};
    }

    ${(props) =>
            props.selected &&
            css`
                border-radius: ${props.theme.button.fill.active.borderRadius};
            `}

    ${(props) => {
        if (props.color === 'primary')
            return css`
                color: ${props.theme.button.fill.primary.color};
                background-color: ${props.theme.button.fill.primary.fill};

                svg path {
                    fill: ${props.theme.button.fill.primary.color};
                }

                &:hover {
                    background-color: ${props.theme.button.fill.primary.hover.fill};
                }

                &:active {
                    background-color: ${props.theme.button.fill.primary.active.fill};
                    outline: ${props.theme.button.fill.primary.active.outline};
                }

                ${props.selected &&
                css`
                    background-color: ${props.theme.button.fill.primary.active.fill};
                    outline: ${props.theme.button.fill.primary.active.outline};
                `}
            `;

        if (props.color === 'secondary')
            return css`
                color: ${props.theme.button.fill.secondary.color};
                background-color: ${props.theme.button.fill.secondary.fill};

                svg path {
                    fill: ${props.theme.button.fill.secondary.color};
                }

                ${props.selected &&
                css`
                    background-color: ${props.theme.button.fill.secondary.active.fill};
                    outline: ${props.theme.button.fill.secondary.active.outline};
                    color: ${props.theme.button.fill.secondary.hover.color};

                    svg path {
                        fill: ${props.theme.button.fill.secondary.hover.color};
                    }
                `}
                &:hover {
                    background-color: ${props.theme.button.fill.secondary.hover.fill};
                    color: ${props.theme.button.fill.secondary.hover.color};

                    svg path {
                        fill: ${props.theme.button.fill.secondary.hover.color};
                    }
                }

                &:active {
                    background-color: ${props.theme.button.fill.secondary.active.fill};
                    outline: ${props.theme.button.fill.secondary.active.outline};
                    color: ${props.theme.button.fill.secondary.hover.color};

                    svg path {
                        fill: ${props.theme.button.fill.secondary.hover.color};
                    }
                }
            `;

        if (props.color === 'success')
            return css`
                color: ${props.theme.button.fill.success.color};
                background-color: ${props.theme.button.fill.success.fill};

                svg path {
                    fill: ${props.theme.button.fill.success.color};
                }

                &:hover {
                    background-color: ${props.theme.button.fill.success.hover.fill};
                }

                &:active {
                    background-color: ${props.theme.button.fill.success.active.fill};
                    outline: ${props.theme.button.fill.success.active.outline};
                }

                ${props.selected &&
                css`
                    background-color: ${props.theme.button.fill.success.active.fill};
                    outline: ${props.theme.button.fill.success.active.outline};
                `}
            `;

        if (props.color === 'error')
            return css`
                color: ${props.theme.button.fill.error.color};
                background-color: ${props.theme.button.fill.error.fill};

                svg path {
                    fill: ${props.theme.button.fill.error.color};
                }

                &:hover {
                    background-color: ${props.theme.button.fill.error.hover.fill};
                }

                &:active {
                    background-color: ${props.theme.button.fill.error.active.fill};
                    outline: ${props.theme.button.fill.error.active.outline};
                }

                ${props.selected &&
                css`
                    background-color: ${props.theme.button.fill.error.active.fill};
                    outline: ${props.theme.button.fill.error.active.outline};
                `}
            `;

        if (props.color === 'legacy')
            return css`
                color: ${props.theme.colors.white};
                background-color: ${props.theme.colors.main};

                svg path {
                    fill: ${props.theme.colors.white};
                }

                &:hover {
                    background-color: ${props.theme.colors.mainHover};
                }

                &:active {
                    background-color: ${props.theme.colors.mainHover};
                    outline: 1px solid ${props.theme.colors.mainHover};
                }

                ${props.selected &&
                css`
                    background-color: ${props.theme.colors.mainHover};
                    outline: 1px solid ${props.theme.colors.mainHover};
                `}
            `;

        return css`
            color: ${props.theme.button.fill.dark.color};
            background-color: ${props.theme.button.fill.dark.fill};

            svg path {
                fill: ${props.theme.button.fill.dark.color};
            }

            &:hover {
                background-color: ${props.theme.button.fill.dark.hover.fill};
            }

            &:active {
                background-color: ${props.theme.button.fill.dark.active.fill};
                outline: ${props.theme.button.fill.dark.active.outline};
            }

            ${props.selected &&
            css`
                background-color: ${props.theme.button.fill.dark.active.fill};
                outline: ${props.theme.button.fill.dark.active.outline};
            `}
        `;
    }}
`;

export const $Outline = styled($Base)`
    background-color: transparent;

    &:active {
        outline: ${(props) => props.theme.button.outline.active.outline};
        box-shadow: ${(props) => props.theme.button.outline.active.outline};
    }

    ${(props) =>
            props.selected &&
            css`
                outline: ${props.theme.button.outline.active.outline};
                box-shadow: ${props.theme.button.outline.active.outline};
            `}
    &:disabled {
        border: ${(props) => props.theme.button.outline.disabled.border};
        color: ${(props) => props.theme.button.outline.disabled.color};
        background-color: ${(props) =>
                props.theme.button.outline.disabled.backgroundColor};

        svg path {
            fill: ${(props) => props.theme.button.outline.disabled.color};
        }
    }

    ${(props) => {
        if (props.color === 'primary')
            return css`
                border: ${props.theme.button.outline.primary.border};
                color: ${props.theme.button.outline.primary.color};

                svg path {
                    fill: ${props.theme.button.outline.primary.color};
                }

                &:hover {
                    border: ${props.theme.button.outline.primary.hover.border};
                    color: ${props.theme.button.outline.primary.hover.color};

                    svg path {
                        fill: ${props.theme.button.outline.primary.hover.color};
                    }
                }

                &:active {
                    border: ${props.theme.button.outline.primary.active.border};
                    color: ${props.theme.button.outline.primary.active.color};
                    background-color: ${props.theme.button.outline.primary.active.fill};
                }

                ${props.selected &&
                css`
                    border: ${props.theme.button.outline.primary.active.border};
                    color: ${props.theme.button.outline.primary.active.color};
                    background-color: ${props.theme.button.outline.primary.active.fill};
                `}
            `;

        if (props.color === 'secondary')
            return css`
                border: ${props.theme.button.outline.secondary.border};
                color: ${props.theme.button.outline.secondary.color};

                svg path {
                    fill: ${props.theme.button.outline.secondary.color};
                }

                &:hover {
                    border: ${props.theme.button.outline.secondary.hover.border};
                    color: ${props.theme.button.outline.secondary.hover.color};

                    svg path {
                        fill: ${props.theme.button.outline.secondary.hover.color};
                    }
                }

                &:active {
                    border: ${props.theme.button.outline.secondary.active.border};
                    color: ${props.theme.button.outline.secondary.active.color};
                    background-color: ${props.theme.button.outline.secondary.active.fill};
                }

                ${props.selected &&
                css`
                    border: ${props.theme.button.outline.secondary.active.border};
                    color: ${props.theme.button.outline.secondary.active.color};
                    background-color: ${props.theme.button.outline.secondary.active.fill};
                `}
            `;

        if (props.color === 'success')
            return css`
                border: ${props.theme.button.outline.success.border};
                color: ${props.theme.button.outline.success.color};

                svg path {
                    fill: ${props.theme.button.outline.success.color};
                }

                &:hover {
                    border: ${props.theme.button.outline.success.hover.border};
                    color: ${props.theme.button.outline.success.hover.color};

                    svg path {
                        fill: ${props.theme.button.outline.success.hover.color};
                    }
                }

                &:active {
                    border: ${props.theme.button.outline.success.active.border};
                    color: ${props.theme.button.outline.success.active.color};
                    background-color: ${props.theme.button.outline.success.active.fill};
                }

                ${props.selected &&
                css`
                    border: ${props.theme.button.outline.success.active.border};
                    color: ${props.theme.button.outline.success.active.color};
                    background-color: ${props.theme.button.outline.success.active.fill};
                `}
            `;

        if (props.color === 'error')
            return css`
                border: ${props.theme.button.outline.error.border};
                color: ${props.theme.button.outline.error.color};

                svg path {
                    fill: ${props.theme.button.outline.error.color};
                }

                &:hover {
                    border: ${props.theme.button.outline.error.hover.border};
                    color: ${props.theme.button.outline.error.hover.color};

                    svg path {
                        fill: ${props.theme.button.outline.error.hover.color};
                    }
                }

                &:active {
                    border: ${props.theme.button.outline.error.active.border};
                    color: ${props.theme.button.outline.error.active.color};
                    background-color: ${props.theme.button.outline.error.active.fill};
                }

                ${props.selected &&
                css`
                    border: ${props.theme.button.outline.error.active.border};
                    color: ${props.theme.button.outline.error.active.color};
                    background-color: ${props.theme.button.outline.error.active.fill};
                `}
            `;

        if (props.color === 'legacy')
            return css`
                border: 1px solid ${props.theme.colors.main};
                color: ${props.theme.colors.main};

                svg path {
                    fill: ${props.theme.colors.main};
                }

                &:hover {
                    border: 1px solid ${props.theme.colors.mainHover};
                    color: ${props.theme.colors.mainHover};

                    svg path {
                        fill: ${props.theme.colors.mainHover};
                    }
                }

                &:active {
                    background-color: #58c1d61a;
                }

                ${props.selected &&
                css`
                    background-color: #58c1d61a;
                `}
            `;

        return css`
            border: ${props.theme.button.outline.dark.border};
            color: ${props.theme.button.outline.dark.color};

            svg path {
                fill: ${props.theme.button.outline.dark.color};
            }

            &:hover {
                border: ${props.theme.button.outline.dark.hover.border};
                color: ${props.theme.button.outline.dark.hover.color};

                svg path {
                    fill: ${props.theme.button.outline.dark.hover.color};
                }
            }

            &:active {
                border: ${props.theme.button.outline.dark.active.border};
                color: ${props.theme.button.outline.dark.active.color};
                background-color: ${props.theme.button.outline.dark.active.fill};
            }

            ${props.selected &&
            css`
                border: ${props.theme.button.outline.dark.active.border};
                color: ${props.theme.button.outline.dark.active.color};
                background-color: ${props.theme.button.outline.dark.active.fill};
            `}
        `;
    }}
`;

export const $Text = styled($Base)`
    background-color: transparent;
    font-weight: 400;
    border-radius: ${(props) => props.theme.button.text.borderRadius};
    padding: 4px;
    border: transparent;
    box-shadow: none;

    &:active {
        outline: none;
        box-shadow: none;
    }

    ${(props) =>
            props.selected &&
            css`
                outline: none;
                box-shadow: none;
            `}
    &:focus {
        box-shadow: ${(props) => props.theme.button.text.focus.boxShadow};

        outline: ${(props) => props.theme.button.text.focus.outline};
        outline-offset: ${(props) => props.theme.button.text.focus.outlineOffset};
        border-radius: ${(props) => props.theme.button.text.borderRadius};
    }

    &:disabled {
        opacity: ${(props) => props.theme.button.text.disabled.opacity};
        color: ${(props) => props.theme.button.text.disabled.color};
        background-color: ${(props) =>
                props.theme.button.text.disabled.backgroundColor};

        svg path {
            fill: ${(props) => props.theme.button.text.disabled.color};
        }
    }

    ${(props) => {
        if (props.color === 'primary')
            return css`
                color: ${props.theme.button.text.primary.color};

                svg path {
                    fill: ${props.theme.button.text.primary.color};
                }

                &:hover {
                    background-color: ${props.theme.button.text.primary.hover.fill};
                }

                &:focus {
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.primary.active.color};
                }

                &:active {
                    color: ${props.theme.button.text.primary.active.color};
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.primary.active.color};

                    svg path {
                        fill: ${props.theme.button.text.primary.active.color};
                    }
                }

                ${props.selected &&
                css`
                    color: ${props.theme.button.text.primary.active.color};
                    border-bottom: 1px solid ${props.theme.button.text.primary.active.color};
                    border-radius: 0;
                    background-color: transparent !important;
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.primary.active.color};

                    svg path {
                        fill: ${props.theme.button.text.primary.active.color};
                    }
                `}
            `;
        if (props.color === 'primary2')
            return css`
                color: ${({ theme }) => theme.colors.primary2};

                svg path {
                    fill: ${({ theme }) => theme.colors.primary2} !important;
                }

                &:active {
                    background-color: unset;
                }

                &:focus {
                    outline: none !important;
                    border-color: ${({ theme }) => theme.colors.baseWhite};
                    box-shadow: 0 0 0 0 ${({ theme }) => theme.colors.baseWhite};
                }
            `;

        if (props.color === 'darkInfo')
            return css`
                color: ${({ theme }) => theme.colors.darkInfo};

                &:hover {
                    border-radius: ${({ theme }) =>
                            theme.borderRadius.radiusSmall} !important;
                    background-color: ${({ theme }) => theme.colors.darkInfo}1A;
                    border-bottom: none !important;
                }

                &:active {
                    background-color: transparent !important;
                    color: ${({ theme }) => theme.colors.darkInfo};
                    border-radius: 0px;
                    border-bottom: 1px solid ${({ theme }) => theme.colors.darkInfo};
                    text-shadow: 0.75px 0 0.5px ${({ theme }) => theme.colors.darkInfo};
                }

                &:focus {
                    border-radius: 0px;
                    background-color: none !important;
                    box-shadow: none;
                    outline: none;
                    text-shadow: 0.75px 0 0.5px ${({ theme }) => theme.colors.darkInfo};
                }

                ${({ selected }) =>
                        selected &&
                        css`
                            color: ${({ theme }) => theme.colors.darkInfo};
                            border-radius: 0px;
                            border-bottom: 1px solid ${({ theme }) => theme.colors.darkInfo};
                            text-shadow: 0.75px 0 0.5px ${({ theme }) => theme.colors.darkInfo};
                        `}
            `;
        if (props.color === 'secondary')
            return css`
                color: ${props.theme.button.text.secondary.color};

                svg path {
                    fill: ${props.theme.button.text.secondary.color};
                }

                &:hover {
                    background-color: ${props.theme.button.text.secondary.hover.fill};
                }

                &:focus {
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.secondary.color};
                }

                &:active {
                    color: ${props.theme.button.text.secondary.active.color};
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.secondary.color};

                    svg path {
                        fill: ${props.theme.button.text.secondary.active.color};
                    }
                }

                ${props.selected &&
                css`
                    color: ${props.theme.button.text.secondary.active.color};
                    border-bottom: 1px solid ${props.theme.button.text.secondary.active.color};
                    border-radius: 0;
                    background-color: transparent !important;
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.secondary.color};

                    svg path {
                        fill: ${props.theme.button.text.secondary.active.color};
                    }
                `}
            `;

        if (props.color === 'success')
            return css`
                color: ${props.theme.button.text.success.color};

                svg path {
                    fill: ${props.theme.button.text.success.color};
                }

                &:hover {
                    background-color: ${props.theme.button.text.success.hover.fill};
                }

                &:focus {
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.success.active.color};
                }

                &:active {
                    color: ${props.theme.button.text.success.active.color};
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.success.active.color};

                    svg path {
                        fill: ${props.theme.button.text.success.active.color};
                    }
                }

                ${props.selected &&
                css`
                    color: ${props.theme.button.text.success.active.color};
                    border-bottom: 1px solid ${props.theme.button.text.success.active.color};
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.success.active.color};
                    border-radius: 0;
                    background-color: transparent !important;

                    svg path {
                        fill: ${props.theme.button.text.success.active.color};
                    }
                `}
            `;

        if (props.color === 'error')
            return css`
                color: ${props.theme.button.text.error.color};

                svg path {
                    fill: ${props.theme.button.text.error.color};
                }

                &:hover {
                    background-color: ${props.theme.button.text.error.hover.fill};
                }

                &:focus {
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.error.active.color};
                }

                &:active {
                    color: ${props.theme.button.text.error.active.color};
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.error.active.color};

                    svg path {
                        fill: ${props.theme.button.text.error.active.color};
                    }
                }

                ${props.selected &&
                css`
                    color: ${props.theme.button.text.error.active.color};
                    border-bottom: 1px solid ${props.theme.button.text.error.active.color};
                    border-radius: 0;
                    text-shadow: 0.75px 0 0.5px ${props.theme.button.text.error.active.color};
                    background-color: transparent !important;

                    svg path {
                        fill: ${props.theme.button.text.error.active.color};
                    }
                `}
            `;

        if (props.color === 'legacy')
            return css`
                color: ${props.theme.colors.main};

                svg path {
                    fill: ${props.theme.colors.main};
                }

                &:hover {
                    // change this color please
                    // or add it to the theme file
                    // or leave it i guess
                    background-color: #58c1d61a;
                }

                &:focus {
                    text-shadow: 0.75px 0 0.5px ${props.theme.colors.mainHover};
                }

                &:active {
                    color: ${props.theme.colors.mainHover};
                    text-shadow: 0.75px 0 0.5px ${props.theme.colors.mainHover};

                    svg path {
                        fill: ${props.theme.colors.mainHover};
                    }
                }

                ${props.selected &&
                css`
                    color: ${props.theme.colors.mainHover};
                    border-bottom: 1px solid ${props.theme.colors.mainHover};
                    border-radius: 0;
                    text-shadow: 0.75px 0 0.5px ${props.theme.colors.mainHover};
                    background-color: transparent !important;

                    svg path {
                        fill: ${props.theme.colors.mainHover};
                    }
                `}
            `;

        return css`
            color: ${props.theme.button.text.dark.color};

            svg path {
                fill: ${props.theme.button.text.dark.color};
            }

            &:hover {
                background-color: ${props.theme.button.text.dark.hover.fill};
            }

            &:focus {
                text-shadow: 0.75px 0 0.5px ${props.theme.button.text.dark.active.color};
            }

            &:active {
                color: ${props.theme.button.text.dark.active.color};
                text-shadow: 0.75px 0 0.5px ${props.theme.button.text.dark.active.color};

                svg path {
                    fill: ${props.theme.button.text.dark.active.color};
                }
            }

            ${props.selected &&
            css`
                color: ${props.theme.button.text.dark.active.color};
                border-bottom: 1px solid ${props.theme.button.text.dark.active.color};
                border-radius: 0;
                text-shadow: 0.75px 0 0.5px ${props.theme.button.text.dark.active.color};
                background-color: transparent !important;

                svg path {
                    fill: ${props.theme.button.text.dark.active.color};
                }
            `}
        `;
    }}
`;

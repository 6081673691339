import React, { FunctionComponent as FC, useState } from "react";
import Theme from "@components/migratedComponents/theme/Theme";
import { FunnelContext, FunnelDataType } from "../../builder/FunnelContext";
import { $FunnelPageContainer } from "../../builder/FunnelPageEditor/FunnelPageEditor.sc";
import { FunnelPageType } from "../../builder/MainContainer";
import FunnelFlow from "../FunnelFlow";
import { Provider as AlertProvider } from "react-alert";
import { AlertTemplate, options } from "@components/molecules/Alert";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export interface IFunnelPagePresenterProps {
  funnelData: any;
}

const FunnelPagePresenter: FC<IFunnelPagePresenterProps> = (
  props: IFunnelPagePresenterProps
) => {
  const [funnelData, setFunnelData] = useState<FunnelDataType>({
    backend: props.funnelData,
    frontend: {
      activeFunnelPage: FunnelPageType.LandingPage,
      mobileMode: false,
      previewMode: false,
      presentMode: true,
    },
    funnelFlow: {
      selectedRatingString: null,
      selectedRatingOn10Scale: null,
    },
    flags: { show_banner: false }
  });

  const updateFrontend = () => setFunnelData({ ...funnelData });
  const updateBackend = () => {};

  return (
    <FunnelContext.Provider
      value={{ funnelData, updateFrontend, updateBackend }}
    >
      <QueryClientProvider client={queryClient}>
        <Theme>
          <AlertProvider template={AlertTemplate} {...options}>
            <$FunnelPageContainer
              className="funnel-page-container"
              backgroundColor={
                funnelData.backend.config_modules_attributes.background_color
              }
              cssAttributes={funnelData.backend.site_attributes.custom_css}
            >
              <FunnelFlow />
            </$FunnelPageContainer>
          </AlertProvider>
        </Theme>
      </QueryClientProvider>
    </FunnelContext.Provider>
  );
};

export default FunnelPagePresenter;

import React, {
  useRef,
  useState,
  useEffect,
  FunctionComponent as FC,
  ReactNode,
  useContext,
  forwardRef,
  useImperativeHandle,
  ComponentPropsWithoutRef,
} from 'react';
import Icon from '../../atoms/Icon';
import P from '../../atoms/P';
import { AccordionContext } from '../Accordion';
import {
  $AccordionContent,
  $AccordionItem,
  $AccordionItemLeftArrow,
  $AccordionTitle,
  $AccordionTitleLeftArrow,
  $H,
  $Icon,
} from './AccordionItem.sc';

export interface IAccordionItem extends ComponentPropsWithoutRef<any> {
  variant?: 'caretLeft' | 'caretRight';
  size?: 'sm' | 'lg';
  active?: string;
  title: string;
  subtitle?: string;
  itemId: string;
  color?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  onClick?: any;
  id?: string;
  testId?: string;
  className?: string;
  children: ReactNode;
  hasSelectedItems?: boolean;
  // openIcon?: ReactNode;
  // closeIcon?: ReactNode;
}

const AccordionItem: FC<IAccordionItem> = forwardRef<
  HTMLDivElement,
  IAccordionItem
>(
  (
    {
      active = 'primary2',
      title,
      variant = 'caretRight',
      itemId,
      color = 'baseDark',
      disabled,
      defaultOpen,
      onClick,
      id = 'accordion-item',
      testId = 'accordion-item',
      className = '',
      children,
      size = 'sm',
      subtitle,
      hasSelectedItems = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [height, setHeight] = useState<undefined | number>(undefined);
    const [isHover, setIsHover] = useState(false);
    const accordionContext = useContext(AccordionContext);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const { singleOpen, activeItem } = accordionContext!.accordionData;
      if (isOpen) {
        setHeight(containerRef?.current?.getBoundingClientRect().height);
        if (singleOpen && itemId) {
          accordionContext!.accordionData.activeItem = itemId;
          accordionContext!.updateAccordionData();
        }
      } else {
        setHeight(0);
        if (singleOpen && itemId && activeItem === itemId) {
          accordionContext!.accordionData.activeItem = undefined;
          accordionContext!.updateAccordionData();
        }
      }
    }, [isOpen, children]);

    useEffect(() => {
      const { singleOpen, activeItem } = accordionContext!.accordionData;
      if (itemId && singleOpen && activeItem && activeItem !== itemId) {
        setIsOpen(false);
      }
    }, [accordionContext!.accordionData]);

    // @ts-ignore
    useImperativeHandle(ref, () => ({ resizeItem }));

    const toggleOpen = () => {
      setIsOpen(!isOpen);
      if (onClick) onClick();
    };

    const resizeItem = () => {
      if (accordionContext!.accordionData.activeItem === itemId) {
        setHeight(containerRef.current?.getBoundingClientRect().height);
      }
    };

    if (variant === 'caretRight')
      return (
        <$AccordionItem
          id={id}
          data-testid={testId}
          className={`m-accordion-item__container ${className}`}
        >
          <$AccordionTitle
            active={active}
            disabled={disabled}
            color={color}
            className="m-accordion-item__title"
            data-testid={`${testId ? testId : 'accordion-item'}-${active}`}
            onClick={disabled ? () => {
            } : toggleOpen}
          >
            <div className="m-accordion-item__title__text">{title}</div>
            <div className="m-accordion-item__title__icon">
              {isOpen ? (
                <Icon icon="chevronUp" color={color} />
              ) : (
                <Icon icon="chevronDown" color={color} />
              )}
            </div>
          </$AccordionTitle>
          <$AccordionContent
            active={active}
            disabled={disabled}
            className={`m-accordion-item__content ${
              isOpen
                ? 'm-accordion-item__content--open'
                : 'm-accordion-item__content--close'
            }`}
            style={{ height }}
            variant={variant}
            size={size}
          >
            <div ref={containerRef} className="container-ref">
              {children}
            </div>
          </$AccordionContent>
        </$AccordionItem>
      );

    return (
      <$AccordionItemLeftArrow
        id={id}
        data-testid={testId}
        className={`m-accordion-item__container ${className}`}
        size={size}
      >
        <$AccordionTitleLeftArrow
          active={active}
          isOpen={isOpen}
          disabled={disabled}
          color={color}
          className="m-accordion-item__title"
          data-testid={`${testId ? testId : 'accordion-item'}-${active}`}
          onClick={disabled ? () => {
          } : toggleOpen}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="m-accordion-item__title__icon">
            {isOpen ? (
              <$Icon
                color={disabled ? 'disabled' : 'baseDarkest'}
                icon="caretDown"
                size={size === 'sm' ? 'md' : size}
              />
            ) : (
              <$Icon
                icon="caretRight"
                color={
                  disabled ? 'disabled' : isHover ? 'baseDarkest' : 'baseDarker'
                }
                size={size === 'sm' ? 'md' : size}
              />
            )}
          </div>
          <$H size={size} hasSelectedItem={hasSelectedItems}>
            {title}
          </$H>
          <P size={size === 'sm' ? '3' : '2'}>{subtitle}</P>
        </$AccordionTitleLeftArrow>
        <$AccordionContent
          active={active}
          disabled={disabled}
          className={`m-accordion-item__content ${
            isOpen
              ? 'm-accordion-item__content--open'
              : 'm-accordion-item__content--close'
          }`}
          style={{ height }}
          variant={variant}
          size={size}
        >
          <div ref={containerRef} className="container-ref">
            {children}
          </div>
        </$AccordionContent>
      </$AccordionItemLeftArrow>
    );
  },
);
export default AccordionItem;

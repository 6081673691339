import styled, { css } from 'styled-components';
import Icon from '../../atoms/Icon';

interface IAccordionItem {
  active?: string;
  disabled?: boolean;
  color?: string;
}

interface ILeftAccordionItem extends IAccordionItem {
  isOpen?: boolean;
}

interface IAccordionItemContent extends IAccordionItem {
  variant: string;
  size: 'sm' | 'lg';
}

export const $Icon = styled(Icon)`
    margin-bottom: 4px;
`;

export const $AccordionItemLeftArrow = styled.div<{
  size: 'sm' | 'lg';
}>`
    margin: ${(props) => (props.size === 'sm' ? '8px 0' : '16px 0')};
`;

export const $AccordionItem = styled.div``;

export const $AccordionTitle = styled.div<IAccordionItem>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${({ disabled, theme }) =>
            disabled ? theme.colors.darkGrayBackground : theme.colors.grayBackground};
    color: ${({ color, theme }) =>
            color ? theme.colors[color] : theme.colors.baseDark};
    padding: 1rem;
    margin-top: 1rem;

    &:hover {
        background-color: ${({ active, disabled, theme }) =>
                !active && !disabled && theme.colors.lightBlueButtonHover};
    }
`;

export const $AccordionContent = styled.div<IAccordionItemContent>`
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    background-color: ${({ disabled, theme }) =>
            disabled ? theme.colors.darkGrayBackground : theme.colors.grayBackground};

    .container-ref {
        padding: ${(props) => (props.variant === 'caretLeft' ? '0' : '16px')};
        display: flex;
        flex-wrap: wrap;
    }
`;

export const $AccordionTitleLeftArrow = styled.div<ILeftAccordionItem>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    color: ${(props) =>
            props.isOpen
                    ? props.theme.colors.baseDarkest
                    : props.disabled
                            ? props.theme.colors.disabled
                            : props.theme.colors.baseDarker};

    &:hover {
        color: ${({ theme }) => theme.colors.baseDarkest};
    }

    ${(props) => {
        if (props.disabled)
            return css`
                cursor: not-allowed;
                pointer-events: none;
            `;

        return ``;
    }}
`;

export const $H = styled.h2<{ size: 'sm' | 'lg'; hasSelectedItem: boolean }>`
    ${(props) =>
            props.size === 'lg'
                    ? props.theme.font.headerOne
                    : props.theme.font.bodyTwo};

    font-weight: bold;

    text-decoration: ${(props) => props.hasSelectedItem && 'underline'};

    margin: 0 0 4px 4px;
`;

import React from "react";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Label from "../../atoms/Label";
import P from "../../atoms/P";
import {
  $BottomLabelsContainer,
  $CheckBoxContainer,
  $InputFormContainer,
  $TopLabelsContainer,
  ILabelsContainer,
} from "./InputForm.sc";

export interface IInputForm extends ILabelsContainer {
  id?: string;
  className?: string;
  testId?: string;
  label?: string;
  hintStatus?: string;
  requiredLabel?: string;
  disabled?: boolean;
  inputId?: string;
  inputName?: string;
  inputValue?: string;
  inputPlaceholder?: string;
  onInputChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  showCheckBox?: boolean;
  checkboxId?: string;
  checkboxName?: string;
  checkBoxLabel?: string;
  checkBoxDefaultChecked?: boolean;
  onCheckBoxChange?: () => void;
}

const InputForm: React.FC<IInputForm> = ({
                                           id,
                                           className,
                                           testId = "input-form",
                                           color,
                                           disabled,
                                           label,
                                           hintStatus,
                                           requiredLabel,
                                           inputId = "input-form-input",
                                           inputName = "input-form-input",
                                           inputValue,
                                           inputPlaceholder,
                                           onInputChange,
                                           showCheckBox,
                                           checkboxId = "input-form-checkbox",
                                           checkboxName = "input-form-checkbox",
                                           checkBoxLabel,
                                           checkBoxDefaultChecked,
                                           onCheckBoxChange,
                                         }) => {
  return (
    <$InputFormContainer id={id} className={className} data-testid={testId}>
      <$TopLabelsContainer disabled={disabled}>
        <Label htmlFor={inputId}>{label}</Label>
        <P>{requiredLabel}</P>
      </$TopLabelsContainer>
      <Input
        id={inputId}
        name={inputName}
        testId={`${testId}-${inputId}`}
        className="m-input-form__input"
        width={"100%"}
        color={color}
        disabled={disabled}
        value={inputValue}
        placeholder={inputPlaceholder}
        onChange={onInputChange}
        variant="outline"
        rightIcon={
          (color == "success" && "checkCircle") ||
          (color == "error" && "exclamationPointCircle") ||
          undefined
        }
      />
      <$BottomLabelsContainer disabled={disabled} color={color}>
        <P>{hintStatus}</P>
      </$BottomLabelsContainer>
      {showCheckBox && (
        <$CheckBoxContainer>
          <Checkbox
            id={checkboxId}
            name={checkboxName}
            testId={`${testId}-${checkboxId}`}
            className="m-input-form__input"
            value={undefined}
            disabled={disabled}
            label={checkBoxLabel}
            checked={checkBoxDefaultChecked}
            onChange={onCheckBoxChange}
          />
        </$CheckBoxContainer>
      )}
    </$InputFormContainer>
  );
};

export default InputForm;

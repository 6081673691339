import styled, { css } from 'styled-components';

export interface ILabelsContainer {
  color?: 'default' | 'error' | 'success';
  disabled?: boolean;
}

export const $InputFormContainer = styled.div`
    .m-input-form__input {
    }

    .m-input-form__checkbox {
    }
`;

const disabled = css`
    cursor: not-allowed;
    pointer-events: none;

    > * {
        color: ${(props) => props.theme.colors.baseLight} !important;
    }
`;

export const BaseLabelsContainer = styled.div<ILabelsContainer>`
    ${(props) => props.disabled && disabled};

    > * {
        color: ${(props) => props.theme.colors.baseDarker};
    }
`;

export const $TopLabelsContainer = styled(BaseLabelsContainer)`
    margin-bottom: 8px;

    > * {
        display: inline-block;
    }

    p {
        float: right;
        font-weight: 700;
    }
`;

export const $BottomLabelsContainer = styled(BaseLabelsContainer)`
    margin-top: 8px;

    ${(props) =>
            props.color == 'error' &&
            css`
                > * {
                    color: ${props.theme.colors.darkError};
                }
            `};

    ${(props) =>
            props.color == 'success' &&
            css`
                > * {
                    color: ${props.theme.colors.darkSuccess};
                }
            `};
`;

export const $CheckBoxContainer = styled.div`
    margin-top: 8px;
`;

import React, {
  FunctionComponent as FC,
  ReactNode,
  useState,
  createContext,
  useMemo,
} from 'react';
import { $Accordion } from './Accordion.sc';
import { IAccordionContext, AccordionDataType } from './utils';

export interface IAccordion {
  active?: string;
  onClick?: any;
  disabled?: boolean;
  id?: string;
  testId?: string;
  className?: string;
  overflow?: string;
  maxHeight?: string;
  maxWidth?: string;
  singleOpen?: boolean;
  children: ReactNode;
}

export const AccordionContext = createContext<IAccordionContext | null>(null);

const Accordion: FC<IAccordion> = ({
                                     active = 'primary2',
                                     disabled = false,
                                     overflow,
                                     maxHeight,
                                     maxWidth,
                                     onClick,
                                     id = 'accordion',
                                     testId = 'accordion',
                                     className = '',
                                     singleOpen = true,
                                     children,
                                   }) => {
  const [accordionData, setAccordionData] = useState<AccordionDataType>({
    activeItem: undefined,
    singleOpen,
  });

  const accordionContextValue = useMemo(
    () => ({
      accordionData,
      updateAccordionData: () => setAccordionData({ ...accordionData }),
    }),
    [accordionData],
  );

  return (
    <$Accordion
      active={active}
      disabled={disabled}
      overflow={overflow}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      id={id}
      data-testid={testId}
      className={`m-accordion__container ${className}`}
      onClick={onClick ? onClick : null}
    >
      <AccordionContext.Provider value={accordionContextValue}>
        {children}
      </AccordionContext.Provider>
    </$Accordion>
  );
};

export default Accordion;

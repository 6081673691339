import styled from 'styled-components';

export const $FileUploadPreviewContainer = styled.div<{
  size?: 'sm' | 'md' | 'lg';
}>`
    width: ${({ size }) =>
            size === 'sm' ? '368px' : size === 'md' ? '592px' : size === 'lg' ? '816px' : '100%'};

    background-color: ${(props) => props.theme.colors.baseLightest};
    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.colors.baseDark};
    filter: drop-shadow(0px 0px 1px rgba(5, 15, 25, 0.05)) drop-shadow(0px 1px 3px rgba(5, 15, 25, 0.1));

    &:hover {
        border-color: ${(props) => props.theme.colors.baseDarkest};
    }
`;

export const $TopRow = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 6px 30px 6px 6px;
    gap: 8px;
`;

export const $FileIconContainer = styled.div`
    align-self: stretch;
`;

export const $FileDescriptionContainer = styled.div`
    align-self: center;
    cursor: pointer;
    overflow: hidden;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
`;

export const $DeleteIconContainer = styled.div`
    align-self: center;
    margin-left: auto;
    cursor: pointer;
`;

export const $BottomRow = styled.div`
    background-color: ${(props) => props.theme.colors.baseLighter};
    width: 100%;
    max-height: 260px;
    border-radius: 0px 0px 6px 6px;
    cursor: pointer;

    * {
        max-height: 260px;
        display: block;
        margin: 0 auto;
    }
`;

export const font = {
    headerOne:
        "font-family: 'Poppins'; font-style: normal; font-weight: 700; font-size: 32px; line-height: 48px; letter-spacing: 1px;",
    headerTwo:
        "font-family: 'Poppins'; font-style: normal; font-weight: 700; font-size: 24px; line-height: 36px; letter-spacing: 1px;",
    bodyOne:
        "font-family: 'Open Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 28px;",
    bodyTwo:
        "font-family: 'Open Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 24px;",
    bodyThree:
        "font-family: 'Open Sans'; font-style: normal; font-weight: 400; font-size: 12px; line-height: 18px;",
    smallText:
        "font-family: 'Open Sans'; font-style: normal; font-weight: 700; font-size: 10px; line-height: 16px; letter-spacing: 1px; text-transform: uppercase",
};

import styled from 'styled-components';
import CopyFill from './svg/CopyFill';
import Gauge from './svg/Gauge';
import Spinner from './svg/Spinner';
import StarHalfFill from './svg/StarHalfFill';
import ThumbDownCircleFill from './svg/ThumbDownCircleFill';
import ThumbUpCircleFill from './svg/ThumbUpCircleFill';
import ToggleLeft from './svg/ToggleLeft';
import ToggleRight from './svg/ToggleRight';
import PaperPlaneFill from './svg/PaperPlaneFill';

export const $Svg = styled.svg<{ color?: string }>`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${({ color, theme }) =>
                color ? theme.colors[color] : theme.colors.main};
    }
`;

export const $CopyFill = styled(CopyFill)`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $StarHalfFill = styled(StarHalfFill)`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $Spinner = styled(Spinner)`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $ThumbDownCircleFill = styled(ThumbDownCircleFill)`
    display: inline-block;
    vertical-align: middle;

    circle {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $ThumbUpCircleFill = styled(ThumbUpCircleFill)`
    display: inline-block;
    vertical-align: middle;

    circle {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $ToggleLeft = styled(ToggleLeft)`
    display: inline-block;
    vertical-align: middle;

    rect {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $ToggleRight = styled(ToggleRight)`
    display: inline-block;
    vertical-align: middle;

    rect {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $Gauge = styled(Gauge)`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

export const $PaperPlaneFill = styled(PaperPlaneFill)`
    display: inline-block;
    vertical-align: middle;

    path {
        fill: ${(props) =>
                props.color ? props.theme.colors[props.color] : props.theme.colors.main};
    }
`;

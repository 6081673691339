export const boxShadow = {
    // Negative elevation
    elevation0:
        '0px 16px 24px 0px rgba(5, 15, 25, 0.08), 0px 0px 1px 0px rgba(5, 37, 39, 0.05)',
    elevation20:
        '0px 1px 3px 0px rgba(5, 37, 39, 0.1), 0px 0px 1px 0px rgba(5, 37, 39, 0.05)',
    elevation40:
        '0px 0px 1px rgba(5, 15, 25, 0.08), 0px 2px 8px rgba(5, 15, 25, 0.15)',
    elevation60:
        '0px 4px 16px 0px rgba(5, 37, 39, 0.15), 0px 0px 1px 0px rgba(5, 37, 39, 0.05)',
    elevation80:
        '0px 8px 20px 0px rgba(5, 37, 39, 0.08), 0px 0px 1px 0px rgba(5, 37, 39, 0.05)',

    // Positive elevation
    elevation100:
        '0px 0px 1px rgba(5, 37, 39, 0.05), 0px -8px 20px rgba(5, 37, 39, 0.08)',
    elevation120:
        '0px 0px 1px rgba(5, 37, 39, 0.05), 0px -1px 3px rgba(5, 37, 39, 0.1)',
    elevation140:
        '0px 0px 1px rgba(5, 37, 39, 0.08), 0px -2px 8px rgba(5, 37, 39, 0.08)',
    elevation160:
        '0px 0px 1px rgba(5, 37, 39, 0.05), 0px -4px 16px rgba(5, 37, 39, 0.08)',
    elevation180:
        '0px 0px 1px rgba(5, 37, 39, 0.05), 0px -8px 20px rgba(5, 37, 39, 0.08)',

    // Other
    focusGlow: '0px 0px 8px rgba(43, 120, 167, 0.85)',
};

import React from 'react';
import { TIcons } from '../../atoms/Icon/iconsLib';
import { IIcon } from '../../atoms/Icon';
import {
  IInputStyles,
  $Fill,
  $LeftIcon,
  $RightIcon,
  $Outline,
  $Input,
  $Line,
} from './Input.sc';

export interface IInput extends IInputStyles {
  variant?: 'outline' | 'fill' | 'line';
  value?: string;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  leftIcon?: TIcons;
  rightIcon?: TIcons;
  leftIconProps?: IIcon;
  rightIconProps?: IIcon;
  id?: string;
  name?: string;
  testId?: string;
}

const Input: React.FC<IInput> = ({
                                   variant = 'outline',
                                   color = 'default',
                                   placeholder = '',
                                   leftIcon = undefined,
                                   rightIcon = undefined,
                                   width,
                                   value,
                                   onChange,
                                   className,
                                   leftIconProps,
                                   rightIconProps,
                                   disabled = false,
                                   id = 'input',
                                   name = 'input',
                                   testId = 'input',
                                 }) => {
  const inputChildren = () => {
    return (
      <>
        {leftIcon && <$LeftIcon icon={leftIcon} {...leftIconProps} />}
        <$Input
          id={id}
          name={name}
          data-testid={testId}
          color={color}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {rightIcon && <$RightIcon icon={rightIcon} {...rightIconProps} />}
      </>
    );
  };

  const inputProps = {
    disabled: disabled,
    color: disabled ? 'default' : color,
    className: className,
    width: width,
  };

  switch (variant) {
    case 'fill':
      return <$Fill {...inputProps}>{inputChildren()}</$Fill>;
    case 'outline':
      return (
        <$Outline className={'outline'} {...inputProps}>
          {inputChildren()}
        </$Outline>
      );
    case 'line':
      return <$Line {...inputProps}>{inputChildren()}</$Line>;
    default:
      return null;
  }
};

export default Input;

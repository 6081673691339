import styled, { css } from 'styled-components';

export const $ToggleTip = styled.div<{
  tipSize: 'sm' | 'md' | 'lg';
  show?: boolean;
  positionX: 'left' | 'right';
  positionY: 'top' | 'bottom';
  windowSize: {};
  buttonPosition: {};
}>`
    .rpt-textbox-container {
        left: calc(
        0% ${({ positionX, tipSize, buttonPosition }) =>
                left(positionX, tipSize, buttonPosition)}
        ) !important;
        top: calc(
                0% -
                ${({ positionY, windowSize, buttonPosition }) =>
                        `${top(positionY, windowSize, buttonPosition)} - 14px`}
        ) !important;

        min-width: 120px;
        max-width: 432px;
        border: 2px solid ${({ theme }) => theme.colors.baseLighter};
        width: ${({ tipSize, windowSize }) =>
                tipWidth(tipSize, windowSize)} !important;

        .rpt-textbox {
            border-radius: 4px;
            box-shadow: ${(props) => props.theme.boxShadow.elevation60};
            background: ${({ theme }) => theme.colors.baseWhite};
            color: ${({ theme }) => theme.colors.baseDarkest};
            ${({ theme }) => theme.font.bodyThree}
            padding: 16px;
            overflow: hidden;
            font-family: 'Open Sans';
            font-style: normal;
        }

        .toggle-button-contents {
            padding: 0px 0px 0px 0px !important;
        }

        .toggle-button-action {
        }

        .toggle-button-action-empty {
            display: none;
        }
    }

    .rpt-arrow {
        display: none;
    }

    .rpt-shadow-container {
        display: none;
    }
`;

function left(positionX, tipSize, buttonPosition) {
  let newPositionX = positionX;

  if (positionX === 'right') {
    const { x } = buttonPosition;
    if (tipSize === 'lg' && x < 440) newPositionX = 'left';
    if (tipSize === 'md' && x < 340) newPositionX = 'left';
    if (tipSize === 'sm' && x < 120) newPositionX = 'left';
  }

  if (newPositionX === 'left') {
    return '- 1px';
  }

  switch (tipSize) {
    case 'sm':
      return '- 90px';
    case 'lg':
      return '- 400px';
    default:
      return '- 294px';
  }
}

function top(positionY, windowSize, buttonPosition) {
  let newPositionY = positionY;
  const { y, scrollTop, height } = buttonPosition;
  const { innerHeight } = window;
  if (positionY === 'bottom') {
    let heightDiff;
    if (scrollTop > 0) {
      heightDiff = y - scrollTop;
      heightDiff = Math.abs(innerHeight - heightDiff);
    } else {
      heightDiff = innerHeight - y;
    }
    if (heightDiff < 200) newPositionY = 'top';
  }

  if (newPositionY === 'top') {
    const newHeight = scrollTop + height + 36;
    return `${newHeight}px`;
  }

  return `${scrollTop - 18}px`;
}

function tipWidth(tipSize, windowSize) {
  let size;
  if (windowSize.innerWidth < 480) {
    size = '120px';
  } else {
    switch (tipSize) {
      case 'sm':
        size = '120px';
        break;
      case 'lg':
        size = '432px';
        break;
      default:
        size = '324px';
        break;
    }
  }
  return size;
}

import React from 'react';

export interface IGauge {
  width: string;
  height: string;
  viewBox: string;
  onClick?: () => void;
  className?: string;
  id?: string;
  color?: string;
}

const Gauge: React.FC<IGauge> = ({
                                   width,
                                   height,
                                   viewBox,
                                   onClick,
                                   className,
                                   id,
                                   color,
                                 }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
    id={id}
  >
    <g clipPath="url(#clip0_2251_52093)">
      <path
        d="M20.3799 8.57L19.1499 10.42C19.7431 11.6031 20.0335 12.9148 19.9951 14.2377C19.9567 15.5606 19.5907 16.8533 18.9299 18H5.06993C4.2111 16.5101 3.85522 14.7831 4.05507 13.0751C4.25491 11.367 4.99984 9.76882 6.17941 8.51743C7.35898 7.26603 8.91039 6.42804 10.6037 6.1277C12.2969 5.82735 14.0419 6.08063 15.5799 6.85L17.4299 5.62C15.5464 4.41222 13.3123 3.871 11.0848 4.08294C8.85738 4.29487 6.76537 5.2477 5.14342 6.78901C3.52147 8.33033 2.46329 10.3711 2.13815 12.5848C1.813 14.7986 2.23968 17.0574 3.34993 19C3.52444 19.3023 3.77502 19.5536 4.07676 19.7291C4.3785 19.9045 4.7209 19.9979 5.06993 20H18.9199C19.2723 20.0014 19.6189 19.9096 19.9244 19.734C20.23 19.5584 20.4837 19.3052 20.6599 19C21.5813 17.4039 22.0437 15.5843 21.996 13.742C21.9484 11.8996 21.3926 10.1063 20.3899 8.56L20.3799 8.57ZM10.5899 15.41C10.7757 15.596 10.9963 15.7435 11.239 15.8441C11.4818 15.9448 11.7421 15.9966 12.0049 15.9966C12.2678 15.9966 12.528 15.9448 12.7708 15.8441C13.0136 15.7435 13.2342 15.596 13.4199 15.41L19.0799 6.92L10.5899 12.58C10.404 12.7657 10.2565 12.9863 10.1558 13.2291C10.0552 13.4719 10.0034 13.7322 10.0034 13.995C10.0034 14.2578 10.0552 14.5181 10.1558 14.7609C10.2565 15.0037 10.404 15.2243 10.5899 15.41Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2251_52093">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Gauge;

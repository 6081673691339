import React, { FC, ReactNode } from 'react';
import { $Label } from './Label.sc';

export interface ILabel {
  htmlFor: string;
  color?: string;
  className?: string;
  children: ReactNode;
}

export const Label: FC<ILabel> = ({
                                    htmlFor,
                                    children,
                                    color = 'baseDarkest',
                                    className,
                                  }) => (
  <$Label className={className} htmlFor={htmlFor} color={color}>
    {children}
  </$Label>
);

export default Label;

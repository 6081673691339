import React, { ReactNode } from 'react';
import { IIcon } from '../../atoms/Icon';
import {
  IButtonStyles,
  $Fill,
  $RightIcon,
  $LeftIcon,
  $Outline,
  $Text,
} from './Button.sc';

export type TButtonType = 'button' | 'submit' | 'reset' | undefined;

export type TButtonVariant = 'fill' | 'outline' | 'text';

export interface IButton extends IButtonStyles {
  disabled?: boolean;
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<Element>,
  ) => void;
  type?: TButtonType;
  id?: string;
  className?: string;
  variant?: TButtonVariant;
  leftIconProps?: IIcon;
  rightIconProps?: IIcon;
  children?: ReactNode;
  selected?: boolean;
}

const Button: React.FC<IButton> = ({
                                     variant = 'fill',
                                     children,
                                     disabled = false,
                                     size = 'md',
                                     className,
                                     id,
                                     type = 'button',
                                     onClick,
                                     leftIcon = undefined,
                                     rightIcon = undefined,
                                     leftIconProps,
                                     rightIconProps,
                                     color = 'primary',
                                     selected,
                                   }) => {
  switch (variant) {
    case 'fill':
      return (
        <$Fill
          type={type}
          id={id}
          className={className}
          size={size}
          disabled={disabled}
          onClick={onClick}
          color={color}
          selected={selected}
        >
          {leftIcon && (
            <$LeftIcon
              icon={leftIcon}
              size={size === 'lg' ? 'md' : size}
              {...leftIconProps}
              color={color}
            />
          )}
          {children}
          {rightIcon && (
            <$RightIcon
              icon={rightIcon}
              size={size === 'lg' ? 'md' : size}
              {...rightIconProps}
              color={color}
            />
          )}
        </$Fill>
      );

    case 'outline':
      return (
        <$Outline
          type={type}
          id={id}
          className={className}
          size={size}
          disabled={disabled}
          onClick={onClick}
          color={color}
          selected={selected}
        >
          {leftIcon && (
            <$LeftIcon
              icon={leftIcon}
              width={size === 'sm' ? '16px' : '24px'}
              height={size === 'sm' ? '16px' : '24px'}
              {...leftIconProps}
            />
          )}
          {children}
          {rightIcon && (
            <$RightIcon
              icon={rightIcon}
              width={size === 'sm' ? '16px' : '24px'}
              height={size === 'sm' ? '16px' : '24px'}
              {...rightIconProps}
            />
          )}
        </$Outline>
      );

    case 'text':
      return (
        <$Text
          type={type}
          id={id}
          className={className}
          size={size}
          disabled={disabled}
          onClick={onClick}
          color={color}
          selected={selected}
        >
          {leftIcon && (
            <$LeftIcon
              icon={leftIcon}
              width={size === 'sm' ? '16px' : '24px'}
              height={size === 'sm' ? '16px' : '24px'}
              {...leftIconProps}
            />
          )}
          {children}
          {rightIcon && (
            <$RightIcon
              icon={rightIcon}
              width={size === 'sm' ? '16px' : '24px'}
              height={size === 'sm' ? '16px' : '24px'}
              {...rightIconProps}
            />
          )}
        </$Text>
      );

    default:
      return null;
  }
};

export default Button;

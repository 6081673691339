import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import P from "@atoms/Typography/Text";
import Button from "@components/migratedComponents/molecules/Button";
import Input from "@molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  IUpdateLocationParams,
  updateLocation,
} from "@components/funnel/hooks/useLocationUpdate";
import SettingCard from "@molecules/SettingCard";

const BusinessPhone: FC = () => {
  const [editing, setEditing] = useState(false);
  const [updatingPhone, setUpdatingPhone] = useState(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const { handleSubmit, register, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: IUpdateLocationParams) =>
    updateLocation(params)
  );

  const submitLocationUpdates = async (params) => {
    mutation.mutate(params, {
      onSuccess: (responseData) => {
        setEditing(false);
        setUpdatingPhone(false);
        funnelData.backend.location_attributes = responseData;
        updateFrontend();
        alert.success(<p>Save was successful</p>);
      },
      onError: () => {
        setUpdatingPhone(false);
        alert.error(
          <>
            <h3>Something went wrong.</h3>
            <p>Please try again later.</p>
          </>
        );
      },
    });
  };

  return (
    <SettingCard
      onClick={() => {
        setEditing(true);
      }}
      onOutsideClick={() => {
        setEditing(false);
      }}
    >
      <P size="3" color="baseDark" fontWeight={600}>
        PHONE
      </P>
      {editing ? (
        <form onSubmit={handleSubmit(submitLocationUpdates)}>
          <Input
            id="uuid"
            name="uuid"
            type="hidden"
            register={register()}
            value={funnelData.backend.location_attributes.uuid}
          />
          <Input
            name="phone"
            register={register()}
            errors={errors.phone}
            defaultValue={funnelData.backend.location_attributes.phone}
          />
          <Button
            color="primary"
            type="submit"
            onClick={() => setUpdatingPhone(true)}
            leftIcon={updatingPhone ? "spinner" : null}
          >
            Save
          </Button>
        </form>
      ) : (
        funnelData.backend.location_attributes.phone && (
          <P size="2" color="baseDarkest" margin="8px 0px 0px 0px">
            {funnelData.backend.location_attributes.phone}
          </P>
        )
      )}
    </SettingCard>
  );
};

export default BusinessPhone;

import React, { FunctionComponent as FC, useContext } from "react";
import P from "@components/atoms/Typography/Text";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import Button from "@components/migratedComponents/molecules/Button";
import BusinessName from "@components/funnel/settings/BusinessName";
import BusinessAddress from "@components/funnel/settings/BusinessAddress";
import BusinessPhone from "@components/funnel/settings/BusinessPhone";
import BusinessEmail from "@components/funnel/settings/BusinessEmail";
import BusinessWebsite from "@components/funnel/settings/BusinessWebsite";
import TimeZoneSettings from "@components/funnel/settings/TimeZone";
import BusinessShortName from "@components/funnel/settings/BusinessShortName";
import BusinessInternalId from "@components/funnel/settings/BusinessInternalId";
import CustomDomain from "@components/funnel/settings/CustomDomain";
import MultiLocationConversion from "@app/javascript/components/funnel/settings/MuliLocationConversion";

const BusinessSettings: FC = () => {
  const { funnelData } = useContext(FunnelContext);
  const connectLocationLink = `/business/new?connect=true&page_uuid=${funnelData.backend.page_attributes.uuid}&site_uuid=${funnelData.backend.site_attributes.uuid}`;

  return (
    <>
      <P color="baseDarkest" margin="0px 0px 16px 0px" fontWeight={600}>
        Business Information
      </P>
      <BusinessName />
      {Object.keys(funnelData.backend.location_attributes).length == 0 ? (
        <Button
          className="connect-to-business-button"
          color="primary"
          onClick={() => {
            window.location.href = connectLocationLink;
          }}
          rightIcon="arrowCircleRight"
        >
          Connect to Business
        </Button>
      ) : (
        <>
          <BusinessAddress />
          <BusinessPhone />
          <BusinessEmail />
          <BusinessWebsite />
          {!funnelData.backend.site_attributes.multilocation && (
            <MultiLocationConversion />
          )}

          <P color="baseDarkest" margin="16px 0px" fontWeight={600}>
            Time Zone
          </P>
          <TimeZoneSettings />
        </>
      )}
      <P color="baseDarkest" margin="32px 0px 16px 0px" fontWeight={600}>
        Other Information
      </P>
      <BusinessShortName />
      <BusinessInternalId />
      <CustomDomain />
    </>
  );
};

export default BusinessSettings;

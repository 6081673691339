import styled from 'styled-components';

type IAccordion = {
  active: string;
  disabled?: boolean;
  overflow?: string;
  maxHeight?: string;
  maxWidth?: string;
  color?: string;
};

export const $Accordion = styled.div<IAccordion>`
    font-family: 'Open Sans';
    max-width: 600px;
    margin: 1rem auto;
    ${({ overflow }) => overflow && `overflow: ${overflow};`}
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}
`;

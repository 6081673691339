import React from 'react';
import { icons, TIcons } from './iconsLib';
import {
  $Svg,
  $CopyFill,
  $Spinner,
  $StarHalfFill,
  $ThumbDownCircleFill,
  $ThumbUpCircleFill,
  $ToggleLeft,
  $ToggleRight,
  $Gauge,
  $PaperPlaneFill,
} from './Icon.sc';

export interface IIcon {
  icon: TIcons;
  color?: string;
  viewBox?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  id?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const determineSize = (size: 'sm' | 'md' | 'lg') =>
  size === 'sm' ? '16px' : size === 'md' ? '24px' : '36px';

export const Icon: React.FC<IIcon> = ({
                                        icon,
                                        color,
                                        width = '24px',
                                        height = '24px',
                                        viewBox = '0 0 24 24',
                                        onClick,
                                        id,
                                        className,
                                        size,
                                        onMouseEnter,
                                        onMouseLeave,
                                      }) => {
  switch (icon) {
    case 'copyFill':
      return (
        <$CopyFill
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'starHalfFill':
      return (
        <$StarHalfFill
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'spinner':
      return (
        <$Spinner
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'thumbDownCircleFill':
      return (
        <$ThumbDownCircleFill
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'thumbUpCircleFill':
      return (
        <$ThumbUpCircleFill
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'toggleLeft':
      return (
        <$ToggleLeft
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'toggleRight':
      return (
        <$ToggleRight
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'gauge':
      return (
        <$Gauge
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );
    case 'paperPlaneFill':
      return (
        <$PaperPlaneFill
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          viewBox={viewBox}
          onClick={onClick}
          className={className}
          id={id}
          color={color}
        />
      );

    default:
      return (
        <$Svg
          viewBox={viewBox}
          width={size ? determineSize(size) : width}
          height={size ? determineSize(size) : height}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          color={color}
          role="img"
          onClick={onClick}
          id={id}
          className={className}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <title>{icon}</title>
          {icons[icon].map((iconDetails) => (
            <path
              key={iconDetails.path}
              d={iconDetails.path}
              clipRule={iconDetails.clipRule || 'evenodd'}
              fillRule={iconDetails.fillRule || 'evenodd'}
            />
          ))}
        </$Svg>
      );
  }
};

export default Icon;

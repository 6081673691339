import React from 'react';
import Icon from '../../atoms/Icon';
import P from '../../atoms/P';

import {
  $FileUploadPreviewContainer,
  $TopRow,
  $FileIconContainer,
  $FileDescriptionContainer,
  $DeleteIconContainer,
  $BottomRow,
} from './FileUploadPreview.sc';

export interface IFileUploadPreview {
  id?: string;
  className?: string;
  testId?: string;
  fileName: string;
  fileSize: string;
  size?: 'sm' | 'md' | 'lg';
  onDelete: () => void;
  onClick: () => void;
  image?: React.ReactNode;
}

export const FileUploadPreview: React.FC<IFileUploadPreview> = ({
                                                                  id,
                                                                  className,
                                                                  testId = 'file-upload-preview',
                                                                  fileName,
                                                                  fileSize,
                                                                  size,
                                                                  onDelete,
                                                                  onClick,
                                                                  image,
                                                                }) => {
  return (
    <$FileUploadPreviewContainer
      size={size}
      id={id}
      className={className}
      data-testid={testId}
    >
      <$TopRow>
        <$FileIconContainer>
          <Icon
            icon={image ? 'image' : 'file'}
            width="72px"
            height="72px"
            color="baseDark"
          />
        </$FileIconContainer>
        <$FileDescriptionContainer onClick={onClick}>
          <P color="baseDarkest">{fileName}</P>
          <P size="2" color="baseDarker">
            {fileSize}
          </P>
        </$FileDescriptionContainer>
        <$DeleteIconContainer onClick={onDelete}>
          <Icon icon="trash" color="darkError" />
        </$DeleteIconContainer>
      </$TopRow>
      {image && (
        <$BottomRow data-testid="image-preview" onClick={onClick}>
          {image}
        </$BottomRow>
      )}
    </$FileUploadPreviewContainer>
  );
};

export default FileUploadPreview;

import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useAlert } from "react-alert";
import { $ReviewSiteFormContainer } from "./AddReviewSite.sc";
import Input from "@molecules/Input";
import Button from "@atoms/Button";
import { colors } from "@styles/colors";
import { AddReviewSiteContext } from "./index";
import spinnerIcon from "@components/funnel/settings/spinner-24.svg";
import Icon from "@atoms/Icon";
import P from "@atoms/Typography/Text";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  ICreateLinkMutationParams,
  useLinkCreate,
} from "@components/funnel/hooks/useLinkCreate";
import _ from "lodash";
import Checkbox from "@atoms/Checkbox";
import {
  countShowedLinks,
  errorMessage,
  getLink,
  wordCounting,
  MAX_SELECTED_LINKS,
} from "@components/funnel/settings/AddAReviewSite/utils";
import ReviewSiteLogo from "@components/funnel/settings/AddAReviewSite/ReviewSiteLogo";
import ReactHtmlParser from "react-html-parser";
import ToggleTip from "@components/migratedComponents/molecules/ToggleTip";

export interface IReviewSiteForm {
  categoryId: string;
  target: any;
}

const MIN_WORD_COUNT = 25;

const ReviewSiteForm: FC<IReviewSiteForm> = ({ categoryId, target }) => {
  const {
    uuid: targetUuid,
    title,
    domain,
    slug,
    notes,
    review_html,
    switch_is_monitorable,
    monitoring_unsupported_html,
    hide_funnel_cta,
  } = target;

  const { handleSubmit, getValues, setValue, register, errors } = useForm();

  const [savingUrls, setSavingUrls] = useState(false);
  const [link, setLink] = useState(null);

  const alert = useAlert();

  const { funnelData, updateBackend } = useContext(FunnelContext);

  const {
    activeTarget,
    clearActiveTarget,
    updateSelectedTarget,
    currentActiveTarget,
    getLogoError,
  } = useContext(AddReviewSiteContext);

  useEffect(() => {
    if (activeTarget) {
      const newLink = getLink(funnelData, categoryId, slug);
      if (newLink) setLink(newLink);
    }
  }, [activeTarget]);

  useEffect(() => {
    if (link) {
      setValue("url", "");
      setValue("monitor_url", "");
    }
  }, [link]);

  const createLinkMutation = useMutation((params: ICreateLinkMutationParams) =>
    useLinkCreate(params)
  );

  const createLinkMutationMutate = async () => {
    createLinkMutation.mutate(
      {
        site_id: funnelData.backend.site_attributes.id,
        page_id: funnelData.backend.page_attributes.id,
        title,
        url: getValues("url"),
        monitor_url: getValues("monitor_url"),
        category: slug,
        custom_header: review_html,
        category_id: categoryId,
        switch_opens_in_new_win: getValues("switch_opens_in_new_win"),
        switch_show: countShowedLinks(funnelData) < MAX_SELECTED_LINKS,
      },
      {
        onSuccess: (link) => {
          setSavingUrls(false);
          currentActiveTarget(categoryId, targetUuid, link.uuid);
          updateSelectedTarget({ categoryId, targetUuid, linkUuid: link.uuid });
          funnelData.backend.links_attributes.push({ ...link });
          updateBackend();
          alert.success(<p>Save was successful</p>);
          clearActiveTarget();
        },
        onError: (error) => {
          setSavingUrls(false);
          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage(error)}</p>
            </>
          );
        },
      }
    );
  };

  return (
    <$ReviewSiteFormContainer
      key={`review-site-form-${categoryId}-${targetUuid}`}
      data-testid={`review-site-form-${categoryId}-${targetUuid}`}
    >
      <div className="review-site-form-header">
        <ReviewSiteLogo
          link={{ ...target, categoryId }}
          logoError={getLogoError(categoryId, targetUuid)}
          style={{
            justifyContent: "flex-start",
            width: "fit-content",
            paddingRight: "10px",
          }}
        />
        <div className="review-site-form-title">{title}</div>
        <div
          className="review-site-form-close"
          data-testid={`review-site-form-close-${categoryId}-${targetUuid}`}
          onClick={() => clearActiveTarget()}
        >
          <Icon icon="snackBarClose" color={colors.baseDarkest} />
        </div>
      </div>
      <form onSubmit={handleSubmit(createLinkMutationMutate)}>
        {!hide_funnel_cta && (
          <>
            <div className="review-site-form-label">
              <P color="baseDark" size="3" fontWeight={600}>
                URL
              </P>
              {notes && (
                <ToggleTip
                  icon="exclamationPointCircle"
                  iconSize="sm"
                  color="baseDark"
                  className="review-site-form-toggle-tip"
                  tipSize={wordCounting(notes) <= MIN_WORD_COUNT ? "md" : "lg"}
                  scrollRef={funnelData.frontend.leftSectionRef}
                >
                  {ReactHtmlParser(notes)}
                </ToggleTip>
              )}
            </div>
            <Input
              id="url"
              name="url"
              testId="review-site-form-input-url"
              register={register({
                required: "Please enter URL.",
              })}
              errors={errors.url}
              defaultValue={`https://${domain ? domain.toLowerCase() : ""}`}
              placeholder="https://"
            />

            <div className="review-site-form-checkbox">
              <Checkbox
                id="switch_opens_in_new_win"
                name="switch_opens_in_new_win"
                testId="link-switch_opens_in_new_win"
                register={register()}
                label="Open link in a New Tab?"
                defaultChecked={true}
                color="baseDark"
                size="small"
              />
            </div>
          </>
        )}

        {switch_is_monitorable && (
          <>
            <div className="review-site-form-label">
              <P color="baseDark" size="3" fontWeight={600}>
                REVIEW MONITORING URL
              </P>
              {review_html ? (
                <ToggleTip
                  icon="exclamationPointCircle"
                  iconSize="sm"
                  color="baseDark"
                  className="review-site-form-toggle-tip"
                  tipSize={
                    wordCounting(review_html) <= MIN_WORD_COUNT ? "md" : "lg"
                  }
                  scrollRef={funnelData.frontend.leftSectionRef}
                >
                  {ReactHtmlParser(review_html)}
                </ToggleTip>
              ) : (
                <ToggleTip
                  icon="exclamationPointTriangle"
                  iconSize="sm"
                  color="baseDark"
                  className="review-site-form-toggle-tip"
                  tipSize={
                    wordCounting(monitoring_unsupported_html) + 1 <=
                    MIN_WORD_COUNT
                      ? "md"
                      : "lg"
                  }
                  scrollRef={funnelData.frontend.leftSectionRef}
                >
                  <span className="review-site-form-toggle-tip-warning">
                    Warning:
                  </span>
                  {ReactHtmlParser(monitoring_unsupported_html)}
                </ToggleTip>
              )}
            </div>
            <Input
              id="monitor_url"
              name="monitor_url"
              testId="review-site-form-input-monitor-url"
              register={register()}
              defaultValue=""
              placeholder={`https://${domain ? domain.toLowerCase() : ""}`}
            />
          </>
        )}

        <Button
          testId={`review-site-form-save-${categoryId}-${targetUuid}`}
          color="primary1"
          onClick={() => setSavingUrls(_.isEmpty(errors))}
          leftIcon={savingUrls ? spinnerIcon : null}
        >
          Save
        </Button>
      </form>
    </$ReviewSiteFormContainer>
  );
};

export default ReviewSiteForm;

import React from 'react';

export interface IToggleRight {
  width: string;
  height: string;
  viewBox: string;
  onClick?: () => void;
  className?: string;
  id?: string;
  color?: string;
}

const ToggleRight: React.FC<IToggleRight> = ({
                                               width,
                                               height,
                                               viewBox,
                                               onClick,
                                               className,
                                               id,
                                               color,
                                             }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <title>toggleRight</title>
    <rect width="36" height="20" rx="10" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 16C29.3137 16 32 13.3137 32 10C32 6.68629 29.3137 4 26 4C22.6863 4 20 6.68629 20 10C20 13.3137 22.6863 16 26 16Z"
      fill="white"
    />
  </svg>
);

export default ToggleRight;

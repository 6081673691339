import styled, { css } from 'styled-components';
import { TButtonColors, TButtonSizes } from '.';

export const $ClickableArea = styled.div<{
  color: TButtonColors;
  selected: boolean;
  size: TButtonSizes;
  expandClickArea: boolean;
}>`
    width: fit-content;
    height: fit-content;
    padding: ${(props) => (props.expandClickArea ? '8px' : '0')};

    &:hover {
        cursor: pointer;

        button {
            cursor: pointer;
            ${(props) => {
                if (props.color === 'primary')
                    return css`
                        background-color: ${props.theme.colors.baseLighter};

                        ${props.selected &&
                        css`
                            svg path {
                                fill: ${props.theme.colors.baseDarkest};
                            }
                        `};
                    `;

                if (props.color === 'primary2')
                    return css`
                        background-color: ${props.theme.colors.baseLighter};
                        ${props.selected &&
                        css`
                            background-color: ${props.theme.colors.baseLighter};

                            svg path {
                                fill: ${props.theme.colors.primary2};
                            }
                        `};
                    `;

                if (props.color === 'secondary')
                    return css`
                        background-color: ${props.theme.colors.palette.pink200};

                        svg path {
                            fill: ${props.theme.colors.palette.pink800};
                        }

                        ${props.selected &&
                        css`
                            background-color: ${props.theme.colors.palette.pink200};

                            svg path {
                                fill: ${props.theme.colors.palette.pink800};
                            }
                        `};
                    `;

                if (props.color === 'error')
                    return css`
                        cursor: pointer;
                        background-color: ${props.theme.colors.lightError};

                        svg path {
                            fill: ${props.theme.colors.darkError};
                        }

                        ${props.selected &&
                        css`
                            background-color: ${props.theme.colors.lightError};

                            svg path {
                                fill: ${props.theme.colors.darkError};
                            }
                        `};
                    `;

                if (props.color === 'baseDark')
                    return css`
                        cursor: pointer;

                        svg path {
                            fill: ${props.theme.colors.baseDarker};
                        }

                        ${props.selected &&
                        css`
                            svg path {
                                fill: ${props.theme.colors.baseDarkest};
                            }
                        `};
                    `;
                return css`
                    cursor: pointer;
                `;
            }}
        }
    }

    button {
        border: transparent;
        background-color: transparent;
        border-radius: ${(props) => props.theme.borderRadius.radiusSmall};
        width: ${(props) => (props.size === 'md' ? '32px' : '24px')};
        height: ${(props) => (props.size === 'md' ? '32px' : '24px')};
        padding: auto;

        svg path {
            fill: ${(props) => props.theme.colors.baseDarker};
        }

        &:focus {
            outline: 1px solid ${(props) => props.theme.colors.darkInfo};
            box-shadow: ${(props) => props.theme.boxShadow.focusGlow};
        }

        &:disabled {
            pointer-events: none;

            svg path {
                fill: ${(props) => props.theme.colors.palette.gray500};
            }
        }

        ${(props) => {
            if (props.color === 'primary')
                return css`
                    svg path {
                        fill: ${props.theme.colors.baseDarkest};
                    }

                    &:active {
                        background-color: ${props.theme.colors.baseDarkest};

                        svg path {
                            fill: white;
                        }
                    }

                    ${props.selected &&
                    css`
                        background-color: ${props.theme.colors.palette.silver500};

                        svg path {
                            fill: ${props.theme.colors.baseDarkest};
                        }
                    `};
                `;

            if (props.color === 'primary2')
                return css`
                    svg path {
                        fill: ${props.theme.colors.primary2};
                    }

                    &:active {
                        background-color: ${props.theme.colors.primary2};

                        svg path {
                            fill: white;
                        }
                    }

                    ${props.selected &&
                    css`
                        background-color: ${props.theme.colors.palette.silver500};

                        svg path {
                            fill: ${props.theme.colors.primary2};
                        }
                    `};
                `;

            if (props.color === 'secondary')
                return css`
                    &:active {
                        background-color: ${props.theme.colors.palette.pink800};

                        svg path {
                            fill: white;
                        }
                    }

                    ${props.selected &&
                    css`
                        background-color: ${props.theme.colors.palette.pink200};

                        svg path {
                            fill: ${props.theme.colors.baseDarker};
                        }
                    `};
                `;

            if (props.color === 'error')
                return css`
                    &:active {
                        background-color: ${props.theme.colors.darkError};

                        svg path {
                            fill: white;
                        }
                    }

                    ${props.selected &&
                    css`
                        background-color: ${props.theme.colors.lightError};

                        svg path {
                            fill: ${props.theme.colors.baseDarker};
                        }
                    `};
                `;
            if (props.color === 'baseDark')
                return css`
                    svg path {
                        fill: ${props.theme.colors.baseDark};
                    }

                    &:active {
                        svg path {
                            fill: ${props.theme.colors.baseDarkest};
                        }
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    ${props.selected &&
                    css`
                        svg path {
                            fill: ${props.theme.colors.baseDarkest};
                        }
                    `};
                `;

            return css``;
        }}
    }
`;

import { colors } from './colors.theme';
import { borderRadius } from './borderRadius.theme';
import { palette } from './palette.theme';
import { font } from './font.theme';

export const input = {
    // base styles
    svgFill: colors.baseDark,
    color: colors.baseLight,
    borderRadius: borderRadius.radiusSmall,

    hover: {
        color: '',
        svgFill: '',
    },

    focusWithin: {
        outlineColor: '',
        boxShadow: '',
        svgFill: '',
    },

    // variant styles
    outline: {
        backgroundColor: colors.baseWhite,
        borderColor: colors.baseDark,

        hover: {
            borderColor: colors.baseDarkest,
        },

        active: {
            inputColor: colors.baseDark,
        },

        focusWithin: {
            borderColor: colors.primary1,
        },

        disabled: {
            borderColor: colors.baseLight,
            inputColor: colors.baseLight,
            svgFill: colors.baseLight,
            backgroundColor: '',
        },

        error: {
            borderColor: colors.darkError,
            svgFill: colors.darkError,
        },

        success: {
            borderColor: colors.darkSuccess,
            svgFill: colors.darkSuccess,
        },
    },

    fill: {
        backgroundColor: colors.baseLightest,

        disabled: {
            backgroundColor: palette.gray100,
        },

        error: {
            borderColor: colors.darkError,
            svgFill: colors.baseDarkest,
        },

        success: {
            borderColor: colors.darkSuccess,
            svgFill: colors.baseDarkest,
        },
    },

    line: {
        borderColor: colors.baseLight,

        hover: {
            borderColor: colors.baseDarker,
        },

        disabled: {
            backgroundColor: palette.gray100,
        },

        error: {
            borderColor: colors.darkError,
            svgFill: colors.baseDarkest,
        },

        success: {
            borderColor: colors.darkSuccess,
            svgFill: colors.baseDarkest,
        },
    },

    // control
    control: {
        color: colors.baseDarker,
        font: font.bodyTwo,

        focus: {
            color: colors.baseDarkest,
        },

        error: {
            color: colors.baseDarkest,
        },

        success: {
            color: colors.baseDarkest,
        },
    },
};

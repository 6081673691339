import styled from "styled-components";

const $P = styled.p<{
  color: string;
  fontWeight: number;
  margin: string;
}>`
    color: ${(props) =>
            props.color === "inherit" ? "inherit" : props.theme.colors[props.color]};
    width: fit-content;
    margin: ${(props) => props.margin};
    font-weight: ${(props) => props.fontWeight};
`;

export const $P1 = styled($P)`
    ${(props) => props.theme.font.bodyOne};
`;

export const $P2 = styled($P)`
    ${(props) => props.theme.font.bodyTwo};
`;

export const $P3 = styled($P)`
    ${(props) => props.theme.font.bodyThree};
`;

export const $P4 = styled($P)`
    ${(props) => props.theme.font.smallText};
`;

import React, { ReactNode, FC } from 'react';
import Modal from 'react-modal';
import Icon from '../../../atoms/Icon';
import { TIcons } from '../../../atoms/Icon/iconsLib';
import H from '../../../atoms/H';
import ButtonIcon from '../../ButtonIcon';
import Button from '../../Button';
import {
  $ModalBody,
  $ModalHeader,
  $ModalHeaderCloseButton,
} from './Transactional.sc';

export type ITransactional = {
  id?: string;
  iconColor?:
    | 'primary1'
    | 'primary2'
    | 'darkError'
    | 'darkSuccess'
    | 'baseDark';
  className?: string;
  testId?: string;
  isOpen: boolean;
  size?: 'sm' | 'md' | 'lg';
  titleText?: string;
  titleIcon?: TIcons;
  closeFxn: () => void;
  primaryText: string;
  primaryColor?:
    | 'primary'
    | 'secondary'
    | 'legacy'
    | 'success'
    | 'error'
    | 'dark';
  primaryFxn: () => void;
  withSecondary?: boolean;
  secondaryText?: string;
  secondaryFxn?: () => void;
  children: ReactNode;
};

const widths = {
  sm: '432px',
  md: '656px',
  lg: '880px',
};

const maxHeights = {
  sm: '416px',
  md: '832px',
  lg: '832px',
};

export const modalStyle = {
  overlay: {
    background: 'rgb(5, 15 , 25, .7)',
    mixBlendMode: 'normal',
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: widths.lg,
    height: 'auto',
    maxHeight: maxHeights.lg,
    overflow: 'hidden',
    padding: '32px',
    borderRadius: '8px',
  },
};

export const modalStyleWithProps = (width?: string, height?: string) => {
  const newModalStyles = {};
  Object.assign(newModalStyles, modalStyle);
  // @ts-ignore
  if (width) newModalStyles.content.width = width;
  // @ts-ignore
  if (height) newModalStyles.content.height = height;
  return newModalStyles;
};

const Transactional: FC<ITransactional> = ({
                                             iconColor = 'primary',
                                             id,
                                             className,
                                             testId = 'transactional-modal',
                                             isOpen,
                                             size = 'md',
                                             titleText,
                                             titleIcon,
                                             closeFxn,
                                             primaryText,
                                             primaryFxn,
                                             primaryColor = 'dark',
                                             withSecondary,
                                             secondaryText = 'Cancel',
                                             secondaryFxn = closeFxn,
                                             children,
                                           }) => (
  <Modal
    id={id}
    className={className}
    data-testid={testId}
    isOpen={isOpen}
    onRequestClose={closeFxn}
    style={modalStyleWithProps(widths[size])}
    ariaHideApp={false}
  >
    <$ModalHeader>
      {titleIcon && (
        <Icon
          size="md"
          className="m-modal-transactional__title-icon"
          icon={titleIcon}
          color={iconColor}
        />
      )}
      <H size="2" color="baseDarkest" margin="0px 8px">
        {titleText}
      </H>
      <$ModalHeaderCloseButton>
        <ButtonIcon
          icon="close"
          size="md"
          color="baseDark"
          onClick={closeFxn}
        />
      </$ModalHeaderCloseButton>
    </$ModalHeader>
    <$ModalBody>
      <div className="m-modal-transactional__content">{children}</div>
      <div className="m-modal-transactional__actions">
        {withSecondary && (
          <Button size="lg" variant="text" color="dark" onClick={secondaryFxn}>
            {secondaryText}
          </Button>
        )}
        <Button size="lg" color={primaryColor} onClick={primaryFxn}>
          {primaryText}
        </Button>
      </div>
    </$ModalBody>
  </Modal>
);

export default Transactional;

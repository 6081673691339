import React, {
  FunctionComponent as FC,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import Button from "@components/migratedComponents/molecules/Button";
import Icon from "@atoms/Icon";
import P from "@atoms/Typography/Text";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  ImutationParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  $AcknowledgementContainer,
  $CSSSyntaxEditor,
  $CustomCSSContainer,
  $SaveButtonContainer,
} from "./CustomCSS.sc";
import CodeEditor from "@uiw/react-textarea-code-editor";
import RoundCheckbox from "@atoms/RoundCheckbox";
import { $SettingCard } from "@components/molecules/SettingCard/SettingCard.sc";
import { $CustomDomainContainer } from "@app/javascript/components/funnel/settings/CustomDomain/CustomDomain.sc";
import { AlertModal } from "@app/javascript/components/base/modals/AlertModal";

const CustomCSSInput: FC = () => {
  const { funnelData, updateBackend, updateFrontend } =
    useContext(FunnelContext);
  const configModules = funnelData.backend.config_modules_attributes;
  const [acknowledgeCSS, setAcknowledgeCSS] = useState<boolean>(
    configModules.custom_css_acknowledgement
  );
  const [openDescription, setOpenDescription] = useState<boolean>(
    !configModules.custom_css_acknowledgement
  );
  const [code, setCode] = useState<string>(
    funnelData.backend.site_attributes.custom_css || ""
  );
  const [toggleOpenCSSBox, setToggleOpenCSSBox] = useState(code.length > 1);
  const [showAttention, setShowAttention] = useState<boolean>(false);
  const [clearCSS, setClearCSS] = useState(false);
  const alert = useAlert();
  const mutation = useMutation((params: ImutationParams) => updateSite(params));
  const cssBox = useRef();
  useEffect(() => {
    clearCSS &&
      (updateBackend(),
      updateFrontend(),
      updateCustomCSS(),
      setToggleOpenCSSBox(false));
  }, [clearCSS]);

  useEffect(() => {
    const preview = document.getElementsByClassName(
      "w-tc-editor-preview language-css"
    )[0];
    const textBox = document.getElementsByClassName(
      "w-tc-editor-text"
    )[0] as HTMLElement;
    textBox && (textBox.style.height = `${preview.clientHeight}px`);
  }, [code]);

  const eraseCSS =
    "You are about to remove all your CSS changes. " +
    " Are you sure you want to proceed?";

  function onConfirmDeleteCSS() {
    setCode("");
    setOpenDescription(true);
    configModules.custom_css_acknowledgement = false;
    setToggleOpenCSSBox(false);
    setShowAttention(false);
    setClearCSS(true);
  }

  function toggleCSSBox() {
    toggleOpenCSSBox && code.length > 1
      ? setShowAttention(true)
      : setToggleOpenCSSBox(!toggleOpenCSSBox);
  }

  function updateAcknowledgement() {
    if (acknowledgeCSS && code.length > 1) {
      toggleCSSBox();
      setAcknowledgeCSS(false);
    } else {
      setAcknowledgeCSS(!acknowledgeCSS);
      configModules.custom_css_acknowledgement = acknowledgeCSS;
    }
  }

  function updateCustomCSS() {
    mutation.mutate(
      {
        id: funnelData.backend.site_attributes.id,
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_id: funnelData.backend.page_attributes.id,
        site: { custom_css: code },
      },
      {
        onSuccess: () => {
          code !== "" &&
            alert.success(
              <>
                <p>Your Custom CSS was saved!</p>
              </>
            );
          updateBackend();
        },
        onError: () => {
          alert.error(
            <>
              <h3>Your Custom CSS could not be saved</h3>
              <p>Please try again</p>
            </>
          );
        },
      }
    );
  }

  return (
    <>
      <$SettingCard>
        <$CustomDomainContainer>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <P>CUSTOM CSS</P>
            <RoundCheckbox
              checked={toggleOpenCSSBox}
              onChange={() => toggleCSSBox()}
            />
          </div>
        </$CustomDomainContainer>
        {toggleOpenCSSBox && (
          <>
            {openDescription && (
              <P size="3" color="baseDark" margin="24px 8px">
                Adding custom CSS to your site can break your design. Please use
                caution when using this feature. Our support team will be
                limited in their ability to help you with design related issues
                if you have custom CSS. To continue, please acknowledge the
                statement below.
              </P>
            )}
            <$AcknowledgementContainer>
              <div
                data-testid="acknowledgement-toggle"
                onClick={() => setOpenDescription(!openDescription)}
              >
                <Icon
                  icon={openDescription ? "chevronUp" : "chevronDown"}
                  color="baseDark"
                />
              </div>
              <P size="3" color="baseDark">
                I ACKNOWLEDGE
              </P>
              <div
                onClick={() => updateAcknowledgement()}
                data-testid="CSS-acknowledgement"
              >
                <Icon
                  icon={acknowledgeCSS ? "squareChecked" : "squareUnchecked"}
                  color="baseDark"
                />
              </div>
            </$AcknowledgementContainer>
            {acknowledgeCSS && (
              <$CustomCSSContainer>
                <$CSSSyntaxEditor ref={cssBox}>
                  <CodeEditor
                    value={code}
                    language="css"
                    onChange={(e) => setCode(e.target.value)}
                    padding={4}
                  />
                </$CSSSyntaxEditor>
                <$SaveButtonContainer>
                  <Button onClick={updateCustomCSS} size="lg" color="primary">
                    Save
                  </Button>
                </$SaveButtonContainer>
              </$CustomCSSContainer>
            )}
          </>
        )}
      </$SettingCard>
      <AlertModal
        isOpen={showAttention}
        title={"Are You Sure?"}
        content={eraseCSS}
        onConfirm={() => {
          onConfirmDeleteCSS();
          setCode("");
          updateCustomCSS();
          updateBackend();
        }}
        onCancel={() => setShowAttention(false)}
        confirmBtnTitle={"Confirm"}
        cancelBtnTitle={"Cancel"}
      />
    </>
  );
};

export default CustomCSSInput;

import { borderRadius } from './borderRadius.theme';
import { boxShadow } from './boxShadow.theme';
import { colors } from './colors.theme';

const { palette } = colors;

export const button = {
    // base styles
    borderRadius: borderRadius.radiusCircle,
    boxShadow: boxShadow.elevation40,
    outlineOffset: '2px',
    padding: '',

    disabled: {
        fill: colors.baseLight,
        opacity: '',
    },

    focus: {
        outline: `2px solid ${colors.primary1}`,
        borderRadius: borderRadius.radiusCircle,
        boxShadow: '',
    },
    // variant styles
    fill: {
        active: {
            outlineOffset: '0px',
            borderRadius: borderRadius.radiusCircle,
        },

        primary: {
            fill: colors.primary1,
            color: colors.baseWhite,

            hover: {
                fill: palette.blue700,
                color: palette.white,
            },

            active: {
                outline: `2px solid ${colors.primary1}`,
                fill: colors.primary1,
            },
        },

        secondary: {
            fill: colors.accent2,
            color: colors.baseWhite,

            hover: {
                fill: palette.purple400,
                color: palette.white,
            },

            active: {
                outline: `2px solid ${palette.purple700}`,
                fill: colors.accent2,
            },
        },

        success: {
            fill: colors.darkSuccess,
            color: colors.baseWhite,

            hover: {
                fill: palette.green500,
                color: palette.white,
            },

            active: {
                outline: `2px solid ${palette.green500}`,
                fill: colors.darkSuccess,
            },
        },

        error: {
            fill: colors.darkError,
            color: colors.baseWhite,

            hover: {
                fill: palette.red400,
                color: palette.white,
            },

            active: {
                fill: palette.red700,
                outline: `2px solid ${palette.red400}`,
            },
        },

        dark: {
            fill: colors.baseDarkest,
            color: colors.baseWhite,

            hover: {
                fill: palette.gray800,
                color: palette.white,
            },

            active: {
                outline: `2px solid ${palette.gray800}`,
                fill: colors.baseDarkest,
            },
        },
    },

    outline: {
        active: {
            outline: `2px solid ${colors.primary1}`,
            boxShadow: '',
        },

        disabled: {
            color: colors.baseLight,
            backgroundColor: 'transparent',
            border: `1px solid ${colors.baseLight}`,
        },

        primary: {
            border: `1px solid ${colors.primary1}`,
            color: colors.primary1,

            hover: {
                border: `1px solid ${palette.blue700}`,
                color: palette.blue700,
            },

            active: {
                border: `1px solid ${colors.primary1}`,
                color: colors.primary1,
                fill: 'rgba(0, 109, 255, 0.1)',
            },
        },

        secondary: {
            border: `1px solid ${colors.accent2}`,
            color: colors.accent2,

            hover: {
                border: `1px solid ${palette.purple700}`,
                color: palette.purple700,
            },

            active: {
                border: `1px solid ${colors.accent2}`,
                color: colors.accent2,
                fill: 'rgba(124, 121, 255, 0.1)',
            },
        },

        success: {
            border: `1px solid ${colors.darkSuccess}`,
            color: colors.darkSuccess,

            hover: {
                border: `1px solid ${palette.green500}`,
                color: palette.green500,
            },

            active: {
                border: `1px solid ${colors.darkSuccess}`,
                color: colors.darkSuccess,
                fill: 'rgba(35, 119, 39, 0.1)',
            },
        },

        error: {
            border: `1px solid ${colors.darkError}`,
            color: colors.darkError,

            hover: {
                border: `1px solid ${palette.red400}`,
                color: palette.red400,
            },

            active: {
                border: `1px solid ${colors.darkError}`,
                color: colors.darkError,
                fill: 'rgba(205, 111, 97, 0.1)',
            },
        },

        dark: {
            border: `1px solid ${colors.baseDarkest}`,
            color: colors.baseDarkest,

            hover: {
                border: `1px solid ${palette.gray600}`,
                color: palette.gray600,
            },

            active: {
                border: `1px solid ${colors.baseDarkest}`,
                color: `1px solid ${colors.baseDarkest}`,
                fill: 'rgba(5, 15, 25, 0.1)',
            },
        },
    },

    text: {
        borderRadius: borderRadius.radiusSmall,
        boxShadow: 'none',

        focus: {
            boxShadow: 'none',
            outline: `2px solid ${colors.primary1}`,
            outlineOffset: '2px',
        },

        disabled: {
            color: colors.baseLight,
            opacity: '',
            backgroundColor: 'transparent',
        },

        active: {
            borderRadius: borderRadius.radiusMedium,
        },

        primary: {
            color: colors.primary1,

            hover: {
                fill: 'rgba(0, 109, 255, 0.1)',
            },

            active: {
                color: colors.primary1,
            },
        },

        secondary: {
            color: colors.accent2,

            hover: {
                fill: 'rgba(124, 121, 255, 0.1)',
            },

            active: {
                color: colors.accent2,
            },
        },

        success: {
            color: colors.darkSuccess,

            hover: {
                fill: 'rgba(35, 119, 39, 0.1))',
            },

            active: {
                color: colors.darkSuccess,
            },
        },

        error: {
            color: colors.darkError,

            hover: {
                fill: 'rgba(205, 111, 97, 0.1)',
            },

            active: {
                color: colors.darkError,
            },
        },

        dark: {
            color: colors.baseDarkest,

            hover: {
                fill: 'rgba(5, 15, 25, 0.1)',
            },

            active: {
                color: colors.baseDarkest,
            },
        },
    },
};

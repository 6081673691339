import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { countries } from "countries-list";
import BaseSelect from "@components/base/selects/BaseSelect";

import P from "@atoms/Typography/Text";
import Button from "@components/migratedComponents/molecules/Button";
import Input from "@molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  IUpdateLocationParams,
  updateLocation,
} from "@components/funnel/hooks/useLocationUpdate";
import SettingCard from "@molecules/SettingCard";

const BusinessAddress: FC = () => {
  const [editing, setEditing] = useState(false);
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [selectedCountry, setSelectedCountry] = useState(
    funnelData.backend.location_attributes.country
      ? {
          value: funnelData.backend.location_attributes.country,
          label:
            countries[funnelData.backend.location_attributes.country]["name"],
        }
      : funnelData.backend.location_attributes.auto_selected_country
      ? {
          value: funnelData.backend.location_attributes.auto_selected_country,
          label:
            countries[
              funnelData.backend.location_attributes.auto_selected_country
            ]["name"],
        }
      : { value: null, label: "" }
  );

  const countriesOptions = Object.keys(countries).map((countryKey) => {
    return { value: countryKey, label: countries[countryKey]["name"] };
  });

  const { handleSubmit, register, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: IUpdateLocationParams) =>
    updateLocation(params)
  );

  const submitLocationUpdates = async (params) => {
    mutation.mutate(
      { ...params, country: selectedCountry.value },
      {
        onSuccess: (responseData) => {
          setEditing(false);
          setUpdatingAddress(false);
          funnelData.backend.location_attributes = responseData;
          updateFrontend();
          alert.success(<p>Save was successful</p>);
        },
        onError: () => {
          setUpdatingAddress(false);
          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>Please try again later.</p>
            </>
          );
        },
      }
    );
  };

  const formattedAddress = () => {
    const cleanedStr = [
      funnelData.backend.location_attributes.address1,
      funnelData.backend.location_attributes.address2,
      funnelData.backend.location_attributes.locality,
      funnelData.backend.location_attributes.region,
      funnelData.backend.location_attributes.country,
      funnelData.backend.location_attributes.postal_code,
    ].filter((x) => !!x); // filter from falsey values (null, undefined, empty string)

    return cleanedStr.join(" ");
  };

  return (
    <SettingCard
      onClick={() => {
        setEditing(true);
      }}
      onOutsideClick={() => {
        setEditing(false);
      }}
    >
      <P size="3" color="baseDark" fontWeight={600}>
        ADDRESS
      </P>
      {editing ? (
        <form onSubmit={handleSubmit(submitLocationUpdates)}>
          <Input
            id="uuid"
            name="uuid"
            type="hidden"
            register={register()}
            value={funnelData.backend.location_attributes.uuid}
          />
          <BaseSelect
            name="country"
            value={selectedCountry}
            options={countriesOptions}
            onChange={(country) => {
              setSelectedCountry({
                value: country.value,
                label: country.label,
              });
            }}
            placeholder="Country"
          />
          <div style={{ height: "16px" }} />
          <Input
            label="Street Address"
            labelTheme="light"
            name="address1"
            register={register()}
            errors={errors.address1}
            defaultValue={funnelData.backend.location_attributes.address1}
          />
          <Input
            label="Apt, Suite, Building"
            labelTheme="light"
            name="address2"
            register={register()}
            errors={errors.address2}
            defaultValue={funnelData.backend.location_attributes.address2}
          />
          <Input
            label="City"
            labelTheme="light"
            name="locality"
            register={register()}
            errors={errors.locality}
            defaultValue={funnelData.backend.location_attributes.locality}
          />
          <Input
            label="State"
            labelTheme="light"
            name="region"
            register={register()}
            errors={errors.region}
            defaultValue={funnelData.backend.location_attributes.region}
          />
          <Input
            label="Zip Code"
            labelTheme="light"
            name="postal_code"
            register={register()}
            errors={errors.postal_code}
            defaultValue={funnelData.backend.location_attributes.postal_code}
          />
          <Button
            color="primary"
            type="submit"
            onClick={() => setUpdatingAddress(true)}
            leftIcon={updatingAddress ? "spinner" : null}
          >
            Save
          </Button>
        </form>
      ) : (
        formattedAddress() && (
          <P size="2" color="baseDarkest" margin="8px 0px 0px 0px">
            {formattedAddress()}
          </P>
        )
      )}
    </SettingCard>
  );
};

export default BusinessAddress;

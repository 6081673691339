import React, { useRef } from "react";
import Label from "../../atoms/Label";
import { TIcons } from "../../atoms/Icon/iconsLib";
import { $CheckboxContainer, $HiddenCheckbox, $Checkbox } from "./Checkbox.sc";

export type TCheckboxSize = "sm" | "md" | "max";

export interface ICheckbox {
  label?: string;
  value?: string;
  checked?: boolean;

  onChange: (data: boolean) => void;
  id?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  size?: TCheckboxSize;
  testId?: string;
  deselect?: boolean;
}

export const Checkbox: React.FC<ICheckbox> = ({
                                                label,
                                                value,
                                                checked,
                                                onChange,
                                                size = "sm",
                                                className,
                                                id = "checkbox",
                                                name = "checkbox",
                                                disabled = false,
                                                testId = "checkbox",
                                                deselect = false,
                                              }) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = () => ref.current?.click();

  const handleChange = ({ target }) => {
    onChange(target.checked);
    handleClick();
  };

  const color =
    (disabled && "baseLight") ||
    ((checked || deselect) && "baseDarkest") ||
    "baseDarker";

  const checkboxIcon = deselect
    ? "checkBoxDeselect"
    : checked
      ? "checkBoxFill"
      : "box";


  return (
    <$CheckboxContainer
      disabled={disabled}
      className={className}
      onClick={handleClick}
      size={size as TCheckboxSize}
      deselected={deselect}
    >
      <$HiddenCheckbox
        id={id}
        name={name}
        ref={ref}
        checked={checked}
        onChange={handleChange}
        data-testid={testId}
      />
      <$Checkbox icon={checkboxIcon as TIcons} color={color} size="sm" />
      {label && (
        <Label htmlFor={name} color={color}>
          {label}
        </Label>
      )}
    </$CheckboxContainer>
  );
};

export default Checkbox;

import { DefaultTheme } from 'styled-components';
import { colors } from './colors.theme';
import { font } from './font.theme';
import { boxShadow } from './boxShadow.theme';
import { borderRadius } from './borderRadius.theme';
import { animation } from './animation.theme';
import { button } from './button.theme';
import { input } from './input.theme';

export const theme: DefaultTheme = {
    colors: {
        modalBackground: 'rgba(107, 107, 107, 0.4)',
        white: '#fff',
        // Remove these colors when we move to new color scheme
        catalinaBlue: '#053368',
        pastelDarkBlue: '#007CBA',
        gray: '#939393',
        black: '#000000',
        primaryBase: '#1E90FF',
        lightGray: '#E6E6E6',

        ...colors,
    },
    font,
    boxShadow,
    borderRadius,
    animation,
    button,
    input,
};

import React, { ChangeEvent, useRef } from 'react';
import Icon from '../../atoms/Icon';
import P from '../../atoms/P';
import { $FileInputContainer, $HiddenFileInput } from './FileInput.sc';

export interface IFileInput {
  id?: string;
  className?: string;
  styles?: Object;
  testId?: string;
  name: string;
  accept?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput: React.FC<IFileInput> = ({
                                                  id,
                                                  className,
                                                  styles,
                                                  testId = 'file-input',
                                                  name = 'file-input',
                                                  accept = '*/*',
                                                  disabled = false,
                                                  size,
                                                  onChange,
                                                }) => {
  const hiddenFileInput = useRef(null);

  const openUploader = () => {
    hiddenFileInput.current.click();
  };

  const onClick = () => {
    if (!disabled) openUploader();
  };

  return (
    <$FileInputContainer
      className={className}
      style={styles}
      disabled={disabled}
      size={size}
      onClick={onClick}
    >
      <$HiddenFileInput
        ref={hiddenFileInput}
        id={id}
        data-testid={testId}
        name={name}
        accept={accept}
        className="m-file-input__hidden"
        onChange={onChange}
      />
      <Icon icon="upload" color={disabled ? 'baseDark' : 'primary1'} />
      <P color={disabled ? 'baseDark' : 'primary1'} fontWeight={700}>
        Browse
      </P>
    </$FileInputContainer>
  );
};

export default FileInput;

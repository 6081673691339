import React from 'react';
import { $H1, $H2 } from './H.sc';

export interface IH {
  size?: '1' | '2';
  color?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4';
  className?: string;
  children: React.ReactNode;
  margin?: string;
}

const H: React.FC<IH> = ({
                           size = '1',
                           color = 'baseDarkest',
                           children,
                           className,
                           margin = '0',
                           as = 'h1',
                         }) => {
  if (size === '1') {
    return (
      <$H1 className={className} margin={margin} color={color} as={as}>
        {children}
      </$H1>
    );
  }
  return (
    <$H2 className={className} margin={margin} color={color} as={as}>
      {children}
    </$H2>
  );
};

export default H;

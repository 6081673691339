import styled, { css } from 'styled-components';

export const $HiddenFileInput = styled.input.attrs({ type: 'file' })`
    display: none;
`;

export const $FileInputContainer = styled.div<{
  size?: 'sm' | 'md' | 'lg';
  disabled: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 0px 1px rgba(5, 15, 25, 0.05), 0px 1px 3px rgba(5, 15, 25, 0.1);
    border-radius: 8px;
    padding: 18px;
    ${({ disabled }) =>
            !disabled &&
            css`
                cursor: pointer;
            `
    }

    width: ${({ size }) =>
            size === 'sm' ? '368px' : size === 'md' ? '592px' : size === 'lg' ? '816px' : '100%'};
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.baseLighter : theme.colors.baseLightest};

    &:hover {
        background-color: ${({
                                 theme,
                                 disabled,
                             }) => disabled ? theme.colors.baseLighter : theme.colors.palette.gray100};
    }
`;

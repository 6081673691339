import styled from 'styled-components';

export const $Label = styled.label<{ color: string }>`
    color: ${({ theme, color }) => theme.colors[color]};
    ${(props) => props.theme.font.bodyTwo};

    &:hover {
        cursor: pointer;
    }
`;

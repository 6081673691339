import styled, { css } from 'styled-components';
import Icon from '../../atoms/Icon';

export interface IInputStyles {
  color?: 'default' | 'error' | 'success';
  width?: string;
  disabled?: boolean;
}

const baseInputStyles = (theme) =>
  css`
      svg path {
          fill: ${theme.input.svgFill};
      }

      ${theme.input.color &&
      css`
          input {
              color: ${theme.input.color};
          }
      `}

      ${theme.input.hover &&
      css`
          &:hover {
              input {
                  color: ${theme.input.hover.color};
              }

              svg path {
                  fill: ${theme.input.hover.svgFill};
              }
          }
      `}

      ${theme.input.focusWithin &&
      css`
          &:focus-within {
              outline: 2px solid ${theme.input.focusWithin.outlineColor};
              outline-offset: 1px;
              box-shadow: ${theme.input.focusWithin.boxShadow};

              svg path {
                  fill: ${theme.input.focusWithin.svgFill};
              }
          }
      `}
  `;

export const $Base = styled.div<IInputStyles>`
    ${({ theme }) => baseInputStyles(theme)};
    width: ${(props) => props.width};
    padding: 8px;
    border-radius: ${({ theme }) => theme.input.borderRadius};
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const outlineInputStyles = (theme) =>
  css`
      border: 2px solid ${theme.input.outline.borderColor};

      ${theme.input.outline.hover &&
      css`
          &:hover {
              border: 2px solid ${theme.input.outline.hover.borderColor};
          }
      `}

      ${theme.input.outline.active &&
      css`
          &:active {
              input {
                  color: ${theme.input.outline.active.inputColor};
              }
          }
      `}

      ${theme.input.outline.focusWithin &&
      css`
          &:focus-within {
              border: 2px solid ${theme.input.outline.focusWithin.borderColor};
          }
      `}
  `;

const outlineDisabledStyles = (theme) =>
  css`
      cursor: not-allowed;
      pointer-events: none;

      ${theme.input.outline.disabled.backgroundColor &&
      css`
          background-color: ${theme.input.outline.disabled.backgroundColor};
      `}

      ${theme.input.outline.disabled.borderColor &&
      css`
          border: 2px solid ${theme.input.outline.disabled.borderColor};
      `}

      ${theme.input.outline.disabled.inputColor &&
      css`
          input {
              color: ${theme.input.outline.disabled.inputColor}
          }
      `}

      ${theme.input.outline.disabled.svgFill &&
      css`
          svg path {
              fill: ${theme.input.outline.disabled.svgFill} !important;
          }
      `}
  `;

const outlineErrorStyles = (theme) =>
  css`
      border: 2px solid ${theme.input.outline.error.borderColor};

      svg path {
          fill: ${theme.input.outline.error.svgFill} !important;
      }
  `;

const outlineSuccessStyles = (theme) =>
  css`
      border: 2px solid ${theme.input.outline.success.borderColor};

      svg path {
          fill: ${theme.input.outline.success.svgFill} !important;
      }
  `;

export const $Outline = styled($Base)`
    ${({ theme }) => outlineInputStyles(theme)};
    background-color: ${({ theme }) => theme.input.outline.backgroundColor};

    ${({ disabled, theme }) => disabled && outlineDisabledStyles(theme)}

    ${({ color, theme }) => color === 'error' && outlineErrorStyles(theme)}

    ${({ color, theme }) => color === 'success' && outlineSuccessStyles(theme)}
`;

export const $Fill = styled($Base)<IInputStyles>`
    border: none;
    background-color: ${({ theme }) => theme.input.fill.backgroundColor};

    ${({ disabled, theme }) => disabled &&
            css`
                cursor: not-allowed;
                pointer-events: none;
                background-color: ${theme.input.fill.disabled.backgroundColor};
            `}

    ${({ color, theme }) =>
            color === 'error' &&
            css`
                border: 2px solid ${theme.input.fill.error.borderColor};

                svg path {
                    fill: ${theme.input.fill.error.svgFill} !important;
                }
            `}

    ${({ color, theme }) =>
            color === 'success' &&
            css`
                border: 2px solid ${theme.input.fill.success.borderColor};

                svg path {
                    fill: ${theme.input.fill.success.svgFill} !important;
                }
            `}
`;

export const $Line = styled($Base)`
    border-bottom: 2px solid ${({ theme }) => theme.input.line.borderBottom};
    background-color: transparent;
    border-radius: 0;

    &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.input.line.hover.borderColor};
    }

    ${({ disabled, theme }) =>
            disabled &&
            css`
                cursor: not-allowed;
                pointer-events: none;
                background-color: ${theme.input.line.disabled.backgroundColor};
            `}

    ${({ color, theme }) =>
            color === 'error' &&
            css`
                border-bottom: 2px solid ${theme.input.line.error.borderColor};

                svg path {
                    fill: ${theme.input.line.error.svgFill} !important;
                }
            `}

    ${({ color, theme }) =>
            color === 'success' &&
            css`
                border-bottom: 2px solid ${theme.input.line.success.borderColor};

                svg path {
                    fill: ${theme.input.line.success.svgFill} !important;
                }
            `}
`;

export const $Input = styled.input.attrs({ type: 'text' })<IInputStyles>`
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.input.control.color};

    // font size and line height

    ${({ theme }) => theme.input.control.font}
    &:focus {
        color: ${({ theme }) => theme.input.control.focus.color};
    }

    ${({ color, theme }) =>
            color === 'error' &&
            css`
                color: ${theme.input.control.error.color};
            `}

    ${({ color, theme }) =>
            color === 'success' &&
            css`
                color: ${theme.input.control.success.color};
            `}
`;

export const $LeftIcon = styled(Icon)`
    margin-right: 4px;
`;

export const $RightIcon = styled(Icon)`
    margin-left: 4px;
`;

import React from 'react';

export interface IStarHalfFill {
  width: string;
  height: string;
  viewBox: string;
  onClick?: () => void;
  className?: string;
  id?: string;
  color?: string;
}

const StarHalfFill: React.FC<IStarHalfFill> = ({
                                                 width,
                                                 height,
                                                 viewBox,
                                                 onClick,
                                                 className,
                                                 id,
                                                 color,
                                               }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <title>starHalfFill</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.56 21C17.4001 21.0006 17.2424 20.9629 17.1 20.89L12 18.22L6.90003 20.89C6.41121 21.147 5.80657 20.9592 5.54953 20.4703C5.44621 20.2738 5.41121 20.0486 5.45003 19.83L6.45003 14.2L2.33003 10.2H2.33003C2.06809 9.93861 1.97192 9.55392 2.08003 9.2L2.08003 9.20001C2.19828 8.83743 2.51246 8.57367 2.89003 8.52L8.59003 7.69L11.1 2.56V2.56C11.3408 2.06295 11.9389 1.85516 12.4359 2.09589C12.6384 2.19398 12.802 2.35749 12.9 2.56L15.44 7.68001L21.14 8.51C21.5176 8.56367 21.8318 8.82743 21.95 9.19C22.0581 9.54392 21.962 9.92861 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.999 20.9469 17.7809 21.0102 17.56 21L17.56 21ZM12 16.1C12.1603 16.096 12.3189 16.1339 12.46 16.21L16.23 18.21L15.51 14C15.4533 13.6736 15.5619 13.3403 15.8 13.11L18.8 10.18L14.6 9.56C14.2877 9.49711 14.024 9.28913 13.89 9L12 5.25L10.11 9C9.96319 9.29166 9.6832 9.49325 9.36003 9.54L5.16003 10.16L8.16003 13.09C8.39815 13.3203 8.50675 13.6536 8.45003 13.98L7.73003 18.14L11.5 16.14C11.6598 16.0806 11.8329 16.0668 12 16.1Z"
      fill={color}
    />
    <mask
      id="mask0_132_1772"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="12"
      height="24"
    >
      <rect width="12" height="24" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_132_1772)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.56 21C17.4001 21.0006 17.2424 20.9629 17.1 20.89L12 18.22L6.90003 20.89C6.41121 21.147 5.80657 20.9592 5.54953 20.4703C5.44621 20.2738 5.41121 20.0486 5.45003 19.83L6.45003 14.2L2.33003 10.2C2.06809 9.93861 1.97192 9.55392 2.08003 9.2C2.19828 8.83743 2.51246 8.57367 2.89003 8.52L8.59003 7.69L11.1 2.56C11.3408 2.06295 11.9389 1.85516 12.4359 2.09589C12.6384 2.19398 12.802 2.35749 12.9 2.56L15.44 7.68001L21.14 8.51C21.5176 8.56367 21.8318 8.82743 21.95 9.19C22.0581 9.54392 21.962 9.92861 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.999 20.9469 17.7809 21.0102 17.56 21Z"
        fill={color}
      />
    </g>
  </svg>
);

export default StarHalfFill;

export const palette = {
    white: '#FFFFFF',
    blue100: '#D2EBFF',
    blue200: '#BDE3FF',
    blue300: '#96CCFF',
    blue400: '#4A9DFF',
    blue500: '#006DFF',
    blue600: '#005ED2',
    blue700: '#004DA6',
    blue800: '#003A79',
    blue900: '#00264D',
    yellow100: '#FFF9E6',
    yellow200: '#FFF6D0',
    yellow300: '#FFF3B9',
    yellow400: '#FFE491',
    yellow500: '#FBD76A',
    yellow600: '#F6C945',
    yellow700: '#DAAF33',
    yellow800: '#A17E17',
    yellow900: '#695005',
    purple100: '#E8E6FF',
    purple200: '#D3D0FF',
    purple300: '#B6B2FF',
    purple400: '#9A95FF',
    purple500: '#7C79FF',
    purple600: '#5C3EC4',
    purple700: '#4F27A6',
    purple800: '#36086A',
    purple900: '#29004D',
    gray100: '#C1D1E4',
    gray200: '#9FB3CD',
    gray300: '#657DA0',
    gray400: '#4D658A',
    gray500: '#385073',
    gray600: '#263D5D',
    gray700: '#182C46',
    gray800: '#0D1D30',
    gray900: '#050F19',
    silver100: '#F7F9FA',
    silver200: '#EFF3F5',
    silver300: '#E0E6E9',
    silver400: '#D1D9DE',
    silver500: '#C3CBD2',
    silver600: '#B5BDC4',
    silver700: '#9AA2A9',
    silver800: '#8D959B',
    silver900: '#80878D',
    red100: '#FFE6E6',
    red200: '#F5C3C1',
    red300: '#EBA59F',
    red400: '#CD6F61',
    red500: '#BE5847',
    red600: '#A23D30',
    red700: '#86271E',
    red800: '#6B1510',
    red900: '#4F0806',
    green100: '#D8F9DD',
    green200: '#B5E2AC',
    green300: '#A6D28F',
    green400: '#80BB6D',
    green500: '#5CA550',
    green600: '#3B8E37',
    green700: '#237727',
    green800: '#13601F',
    green900: '#074A18',
    orange100: '#FFD9CF',
    orange200: '#FFCBB8',
    orange300: '#FEB190',
    orange400: '#F89C69',
    orange500: '#F28B45',
    orange600: '#E67300',
    orange700: '#C05500',
    orange800: '#993B00',
    orange900: '#E67E00',
    teal100: '#E6FFFA',
    teal200: '#C9F6EF',
    teal300: '#AEEDE5',
    teal400: '#84DAD4',
    teal500: '#60C7C6',
    teal600: '#2495A0',
    teal700: '#096877',
    teal800: '#055360',
    teal900: '#002B33',
    pink100: '#FFE6F9',
    pink200: '#FBD5F2',
    pink300: '#F6C5EB',
    pink400: '#E3A2D4',
    pink500: '#D082BD',
    pink600: '#BD65A8',
    pink700: '#98367F',
    pink800: '#85246B',
    pink900: '#5F0848',
};

import React from "react";
import Icon from "../../atoms/Icon";
import { TIcons } from "../../atoms/Icon/iconsLib";
import { $ClickableArea } from "./ButtonIcon.sc";

export type TButtonColors =
  | "primary"
  | "primary2"
  | "secondary"
  | "error"
  | "baseDark"
  | "baseDarker";

export type TButtonSizes = "sm" | "md";

export interface IButtonIcon {
  icon: TIcons;
  id?: string;
  color?: TButtonColors;
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<Element>,
  ) => void;
  disabled?: boolean;
  className?: string;
  selected?: boolean;
  size?: TButtonSizes;
  expandClickArea?: boolean;
}

const ButtonIcon: React.FC<IButtonIcon> = ({
                                             id,
                                             icon,
                                             color = "primary",
                                             disabled = false,
                                             className,
                                             onClick,
                                             selected = false,
                                             size = "sm",
                                             expandClickArea = false,
                                           }) => (
  <$ClickableArea
    id={`${id}-clickable-area`}
    onClick={onClick}
    className={`button-icon-clickable-area ${className ? className : ""} ${
      selected ? "button-icon-selected-clickable-area" : ""
    }`}
    color={color as TButtonColors}
    selected={selected}
    size={size as TButtonSizes}
    expandClickArea={expandClickArea}
  >
    <button
      id={`${id}-button`}
      className={`button-icon ${selected ? "button-icon-selected" : ""}`}
      disabled={disabled}
      type="button"
    >
      <Icon id={`${id}-icon`} icon={icon} size={size as TButtonSizes} />
    </button>
  </$ClickableArea>
);

export default ButtonIcon;
